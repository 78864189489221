import './Event.css'

import {
    Button,
    Panel,
    PanelHeader,
    Spacing,
    HorizontalScroll,
    Link, IconButton, Div, Separator, ScreenSpinner
} from "@vkontakte/vkui";
import React, {useEffect, useState} from "react";
import {Icon24ChevronCompactRight, Icon28ChevronBack, Icon12ArrowUpRightOutSquareOutline} from "@vkontakte/icons";

const axios = require('axios').default;
import img1 from './img/1.png';
import img2 from './img/2.png';
import img3 from './img/3.png';
import img4 from './img/4.png';
import img5 from './img/5.png';
import img6 from './img/6.png';
import img7 from './img/7.png';

import list1 from './img/list1.png';
import list2 from './img/list2.png';
import list3 from './img/list3.png';
import list4 from './img/list4.png';
import list5 from './img/list5.png';

import promoImg from './img/promo.jpg';
import contentImg from './img/content_image1.png';
import TabbarLenta from "../../components/TabbarLenta/TabbarLenta";

const Event = (props) => {
    
    const data = props.eventData;
    
    console.log('eventData data ', data)
    
    
    let content = null;
    
    if (data.catalog_id) {
        props.changeSelectedCatalogID(data.catalog_id)
        
    }
    
    
    if (data) {
        const rulesText = data.rules;
        content = <div>
            {data.img ?
                <img src={data.img} className="Event_image"/>
                :
                null
            }
            
            <Div>
                {
                    data.date ?
                        <span className="Event_date">
							{data.date}
						</span>
                        :
                        null
                }
                {
                    data.name ?
                        <div className="Event_title">
                            {data.name}
                        </div>
                        :
                        null
                }
                {
                    data.detail_text ?
                        <div className="Event_descr" dangerouslySetInnerHTML={{__html: data.detail_text}}/>
                        :
                        null
                }
                
                <div>
                    {
                        data.detail_button ?
                            <div>
                                <Button
                                    size='l'
                                    href={data.detail_button_url}
                                    target={"_blank"}
                                    stretched={true}
                                    appearance={'accent'}
                                    style={{color: '#fff', backgroundColor: '#682985'}}
                                    after={<Icon12ArrowUpRightOutSquareOutline aria-hidden/>}
                                >{data.detail_button}</Button>
                                <Spacing size={8}/>
                            </div>
                            :
                            null
                    }
                    {
                        data.catalog_button_name ?
                            <Button
                                size='l'
                                onClick={(e) => {
                                    props.go(e)
                                }}
                                stretched={true}
                                appearance={'accent'}
                                data-to={'catalog'}
                                
                                after={<Icon24ChevronCompactRight aria-hidden/>}
                            >{data.catalog_button_name}</Button>
                            :
                            null
                    }
                
                </div>
            </Div>
            <Spacing size={20}>
                <Separator/>
            </Spacing>
            
            {data.collection ?
                <div>
                    <Div>
                        {data.collection.name ?
                            <div className="Event_title">
                                {data.collection.name}
                            </div>
                            :
                            null
                        }
                        
                        <div className="Event_scroll">
                            <HorizontalScroll>
                                <ul className="Event_items">
                                    
                                    {data.collection.slider.map((collectionItem, index) => {
                                        return (
                                            <li className="Event_item">
                                                <img src={collectionItem['photo' + (index + 1)]}
                                                     className="Event_item_image"/>
                                                <span className="Event_item_text">
                                                    {collectionItem.text1}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            
                            
                            </HorizontalScroll>
    
                            {data.collection.button_block ?
                                <Button
                                    size='l'
                                    href={data.collection.button_url}
                                    target={"_blank"}
                                    stretched={true}
                                    appearance={'accent'}
                                    style={{color: '#fff', backgroundColor: '#682985'}}
                                    after={<Icon12ArrowUpRightOutSquareOutline aria-hidden/>}
                                >{data.collection.button_block}</Button>
                                :
        
                                null
                            }
                        </div>
                        
                        
                    
                    </Div>
                    <Spacing size={20}>
                        <Separator/>
                    </Spacing>
                </div>
                :
                null
            }
            
            
            {data.promo ?
                <div>
                    <Div>
                        <img src={data.promo.promo_img} className="Event_image"/>
                        <Spacing size={20}/>
                        {data.promo.name ?
                            <div className="Event_title">
                                {data.promo.name}
                            </div>
                            :
                            null
                        }
                        
                        {data.promo.text ?
                            <div className="Event_descr" dangerouslySetInnerHTML={{__html: data.promo.text}}/>
                            :
                            null
                        }
                        
                        {data.promo.button ?
                            <div>
                                <Button
                                    size='l'
                                    href={data.promo.button_url}
                                    target={"_blank"}
                                    stretched={true}
                                    appearance={'accent'}
                                    style={{color: '#fff', backgroundColor: '#682985'}}
                                    after={<Icon12ArrowUpRightOutSquareOutline aria-hidden/>}
                                >{data.promo.button}</Button>
                            
                            </div>
                            :
                            null
                        }
                    
                    </Div>
                    <Spacing size={20}>
                        <Separator/>
                    </Spacing>
                </div>
                :
                null
            }
            <Div>
                {data.prize ?
                    <div className="Event_scroll">
                        {data.prize.name ?
                            <div className="Event_title">
                                {data.prize.name}
                            </div>
                            :
                            null
                        }
    
                        {data.prize.text ?
                            <div className="Event_descr" dangerouslySetInnerHTML={{__html: data.prize.text}}/>
                            :
                            null
                        }
                        <HorizontalScroll>
                            
                            <ul className="Event_list">
                                {data.prize.element.map(listItem => {
                                    return (
                                        <li className="Event_list_item">
                                            <img src={listItem.button_img} className="Event_list_item_image"/>
                                            <div className="Event_list_info">
                                                <div>
											<span className="Event_list_item_text">
												{listItem.name}
											</span>
                                                    <span className="Event_list_item_descr">{listItem.text}</span>
                                                </div>
                                                <Button
                                                    size='l'
                                                    href={listItem.button_url}
                                                    target={"_blank"}
                                                    appearance={'accent'}
                                                    style={{color: '#ffffff', backgroundColor: listItem.button_color}}
                                                    after={<Icon12ArrowUpRightOutSquareOutline aria-hidden/>}
                                                >{listItem.button_text}</Button>
                                            </div>
                                        
                                        </li>
                                    )
                                })}
                            
                            </ul>
                        
                        </HorizontalScroll>
                    
                    
                    </div>
                    :
                    null
                }
                
                {
                    rulesText
                        
                        ?
                        <div>
                            <Button onClick={() => {
                                props.openModal('agreement', {rules: rulesText})
                            }}
                                    stretched
                                    size={'l'}
                                    mode={'outline'}
                            >
                                Уведомление об условиях
                            </Button>
                            
                            <Spacing size={20}/>
                        </div>
                        :
                        
                        null
                }
            
            </Div>
        </div>
    } else {
        content = <ScreenSpinner state="loading" id={'event-spinner'}/>;
    }
    
    console.log('rules', data)
    
    
    return (
        <Panel id="event">
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={props.go}
                            data-to='promo'
                        />
                    </IconButton>
                }
            >Лента</PanelHeader>
            
            <div className="Event" style={{paddingBottom: 50, paddingTop: 10}}>
                {content}
            
            </div>
            
            <div style={{zIndex: 7}}>
                <TabbarLenta
                    active={props.active}
                    go={props.go}
                />
            </div>
        </Panel>
    )
}

export default Event