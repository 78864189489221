import {
    useAdaptivity,
    AppRoot,
    SplitLayout,
    SplitCol,
    ViewWidth,
    View,
    Panel,
    PanelHeader,
    Root,
    PanelHeaderBack
} from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";
import Home from './panels/Home/Home'
import Main from './panels/Main/Main'
import Catalog from './panels/Catalog/Catalog'
import Prizes from './panels/Prizes/Prizes'
import Notifications from './panels/Notifications/Notifications'
import BindCard from './panels/BindCard/BindCard'
import Profile from './panels/Profile/Profile'
import PersonalData from './panels/PersonalData/PersonalData'
import Rules from './panels/Rules/Rules'
import Help from './panels/Help/Help'
import Actions from './panels/Actions/Actions'
import History from './panels/History/History'
import Scan from './panels/Scan/Scan'
import Map from './panels/Map/Map'
import Cities from './panels/Cities/Cities'
import Modals from "./components/Modals/Modals";
import Promo from "./panels/Promo/Promo";
import Coupon from "./panels/Coupon/Coupon";
import Event from "./panels/Event/Event";
import {useEffect, useState} from 'react'
import './style.css'
import bridge from '@vkontakte/vk-bridge';
import {default as axios} from "axios";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const queryString = require('query-string');

const App = () => {
    const {viewWidth} = useAdaptivity();
    const [activePanel, setActivePanel] = useState("Home");
    const [activeModal, setActiveModal] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [notificationsIsEnabled, setNotificationsIsEnabled] = useState(false);
    const [userPhoto, setUserPhoto] = useState('');
    const [userCity, setUserCity] = useState('');
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [userID, setUserID] = useState('');
    const [policy, setPolicy] = useState(false);
    const [mapOpenFirstTime, changeMapOpenFirstTime] = useState(true);
    const [selectedMarket, setSelectedMarket] = useState('');
    const [marketType, setMarketType] = useState('hypermarket');
    const [eventData, setEventData] = useState(null);
    
    const changeMarketType = (newType) => {
        setMarketType(newType)
        let event;
        event = document.createEvent("HTMLEvents");
        event.initEvent("setMapFilter", true, true);
        event.eventName = "setMapFilter";
        event.paramValue = newType;
  
        document.dispatchEvent(event);
    }
    
    // $(document).trigger('setMapFilter', $target.data('type'));
    const [selectedCityName, changeSelectedCityName] = useState('');
    const [selectedCityKey, changeSelectedCityKey] = useState('spb');
    const [selectedCatalogID, changeSelectedCatalogID] = useState(1);
    const [selectedCategoryID, changeSelectedCategoryID] = useState(0)
    
    const [actualCatalogCard, changeActualCatalogCard] = useState({
        name: '',
        description: '',
        categoryImg: '',
        date: '',
        discount: '',
        color_button_discount: ''
    });
    // получаем информацию о пользователе
    const [vpnIsActive, setVpnIsActive] = useState(false);
    const [mapCoords, setMapCoords] = useState([59.939095, 30.315868]);
    
    const [pushText, setPushText] = useState(false);
    const defaultParams = window.location.href;
    const searchParams = window.location.search;
    
    const parsedHref = queryString.parse(defaultParams);
    
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    
    
    console.log('parsedHref', parsedHref)
    
    const sign = parsedHref.sign;
    const vk_app_id = parsedHref.vk_app_id;
    const vk_user_id = parsedHref.vk_user_id;
    
    if (!sign) {
        // alert('Нет подключения к сервису VK')
    }
    // document.addEventListener('backbutton', function(e){
    //     e.preventDefault()
    //
    //
    // });
    
    
    
  
    const openAuthPopup = () => {
        bridge.send("VKWebAppGetSilentToken", {"app_id": +vk_app_id})
            .then(data => {
                console.log('VKWebAppGetSilentToken data', data)
                // setEmail(data.email)
                // setPhone(data.phone)
            })
            .catch(error => {
                console.log(error)
            });
    
    
        if (phone === null) {
            bridge
                .send('VKWebAppGetEmail')
                .then(data => {
                    // Обработка события  в случае успеха.
                    console.log(data);
                    setEmail(data.email)
                })
                .catch(error => {
                    console.log(error);
                    // Обработка события в случае ошибки.
                });
        }

        if (email === null) {
            bridge
                .send('VKWebAppGetPhoneNumber')
                .then(data => {
                    // Обработка события  в случае успеха.
                    console.log(data);
                    setPhone(data.phone_number)
                })
                .catch(error => {
                    console.log(error);
                    // Обработка события в случае ошибки.
                });
        }
    }
    useEffect(() => {
        
        
    
        bridge
            .send("VKWebAppGetUserInfo")
            .then(data => {
                console.log('data', data)
                setUserName(data.first_name)
                setUserSurname(data.last_name)
                setUserPhoto(data.photo_100)
                // if (data.city && !userCity) {
                //     changeSelectedCityName(data.city.title)
                // }
                
                setUserID(data.id)
                
                
            })
            .catch(error => {
                console.log(error)
            });
        
        
        // console.log(bridge.supports('VKWebAppGetSilentToken'))
        // window.VK.init({
        //     apiId: vk_app_id
        // });
        //
        // setTimeout(() => {
        //     window.VK.Widgets.Auth('vk_auth', {
        //         onAuth: function (data) {
        //             console.log('data', data)
        //         }
        //     });
        // }, 2000)
        
        // window.location.href = `https://oauth.vk.com/authorize?client_id=${vk_app_id}&redirect_uri=https://oauth.vk.com/blank.html&scope=12&display=mobile`
        
        // bridge.send("VKWebAppGetAuthToken",
        //     {
        //         "app_id": +vk_app_id,
        //         "scope": ""
        //     })
        //     .then(data => {
        //         console.log('VKWebAppGetAuthToken', data)
        //
        //         const access_token = data.access_token;
        //
        //         bridge.send("VKWebAppCallAPIMethod",
        //             {
        //                 "method": "users.get",
        //                 "request_id": "32test",
        //                 "params": {"vk_user_ids": vk_user_id, "v": "5.131", "access_token": access_token}
        //             })
        //             .then(data => {
        //                 console.log('VKWebAppCallAPIMethod', data)
        //
        //
        //             })
        //             .catch(error => {
        //                 console.log('VKWebAppCallAPIMethod', error)
        //             });
        //     })
        //     .catch(error => {
        //         console.log('VKWebAppGetAuthToken', error)
        //     });
        
        
    }, [])
    useEffect(() => {
        bridge.send("VKWebAppStorageGet", {"keys": [userID + '_policy']})
            .then(data => {
                if (data.keys[0].value === 'confirmed') {
                    setPolicy(true)
                }
            })
            .catch(error => {
                console.log(error)
            });
        
    }, [userID])
    
    
    
    
    
    useEffect(() => {
        window.addEventListener('load', function() {
            window.history.pushState({}, '')
        })
    
        window.addEventListener('popstate', (e) => {
            window.history.pushState({}, '')
            console.log('activeModal window.popupOpened ', activeModal, window.popupOpened)
            if (window.popupOpened || activeModal || modalData) {
                modalHandler(null)
                window.scrollTo(0, window.lastScroll);
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 10)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 20)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 30)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 70)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 100)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 150)
                setTimeout(() => {
                    window.scrollTo(0, window.lastScroll);
                }, 200)
                
    
              
                
            }
            else {
                setActivePanel('main')
                modalHandler(null)
            }
        })
        
    }, [])
    
    
    const setStorage = () => {
        bridge.send("VKWebAppStorageSet", {
            key: userID + '_policy',
            value: "confirmed"
        })
            .then(data => {
                // console.log(data)
            })
            .catch(error => {
                console.log(error)
            });
    }
    
    
    const go = e => {
        e.preventDefault();
        setActivePanel(e.currentTarget.dataset.to);
    }
    
    const modalHandler = (modal, data = null) => {
        const body = document.querySelector('body')
        
        
        if (modal) {
            window.popupOpened = true;
            window.lastScroll = window.scrollY;
            disableBodyScroll(body)
        }
        
        else {
            window.popupOpened = false;
     
            
        }
    
        console.log('window.popupOpened', window.popupOpened)
        setModalData(data);
        setActiveModal(modal);
        
      
    
        
        
       
        
    }
    
    
    
    return (
        <AppRoot>
            <SplitLayout
                header={<PanelHeader separator={false}/>}
                modal={<Modals onClose={modalHandler} activeModal={activeModal}
                               openModal={modalHandler} go={go} photo={userPhoto}
                               modalData={modalData} setSelectedMarket={setSelectedMarket}
                               changeMapOpenFirstTime={changeMapOpenFirstTime}
                               changeSelectedCityKey={changeSelectedCityKey}
                               phone={phone}
                               email={email}
                               setPhone={setPhone}
                               setEmail={setEmail}
                               vk_user_id={vk_user_id}
                               selectedCityKey={selectedCityKey}
                />}
            >
                <SplitCol spaced={viewWidth && viewWidth > ViewWidth.MOBILE}>
                    <Root activeView='view1'>
                        <View activePanel={activePanel} id="view1">
                            
                            <Home go={go}
                                  id='Home'
                                  setActivePanel={setActivePanel}
                                  changeSelectedCityKey={changeSelectedCityKey}
                                  setPhone={setPhone}
                                  setEmail={setEmail}
                                  selectedCityKey={selectedCityKey}
                                  changeSelectedCatalogID={changeSelectedCatalogID}
                                  changeSelectedCategoryID={changeSelectedCategoryID}
                                  setPushText={setPushText}
                                  changeMapOpenFirstTime={changeMapOpenFirstTime}
                                  changeSelectedCityName={changeSelectedCityName}
                                  setMapCoords={setMapCoords}
                                  defaultParams={defaultParams}
                                  searchParams={searchParams}
                                  openModal={modalHandler}
                                  setNotificationsIsEnabled={setNotificationsIsEnabled}
                                  changeMarketType={changeMarketType}
                                  eventData={eventData}
                                  setEventData={setEventData}
                            />
                            <Promo go={go}
                                   id='promo'
                                   active={activePanel}
                                   setActivePanel={setActivePanel}
                                   openModal={modalHandler}
                                   selectedCatalogID={selectedCatalogID}

                                   policy={policy}
                                   setStorage={setStorage}
                                   selectedMarket={selectedMarket}
                                   userID={userID}
                                   selectedCityKey={selectedCityKey}
                                   changeSelectedCatalogID={changeSelectedCatalogID}
                                   changeActualCatalogCard={changeActualCatalogCard}
                                   selectedCityName={selectedCityName}
                                   pushText={pushText}
                                   changeMarketType={changeMarketType}
                                   marketType={marketType}
                                   setEventData={setEventData}
                            />
                            <Event go={go}
                                   id='event'
                                   active={activePanel}
                                   setActivePanel={setActivePanel}
                                   openModal={modalHandler}
                                   selectedCatalogID={selectedCatalogID}
                                   eventData={eventData}
                                   setEventData={setEventData}
                                   selectedCityKey={selectedCityKey}
                                   changeSelectedCatalogID={changeSelectedCatalogID}
                                   changeActualCatalogCard={changeActualCatalogCard}
                            />
                            <Main
                                go={go}
                                active={activePanel}
                                policy={policy}
                                setStorage={setStorage}
                                selectedMarket={selectedMarket}
                                id="main"
                                userID={userID}
                                selectedCityKey={selectedCityKey}
                                changeSelectedCatalogID={changeSelectedCatalogID}
                                changeActualCatalogCard={changeActualCatalogCard}
                                selectedCityName={selectedCityName}
                                pushText={pushText}
                                changeMarketType={changeMarketType}
                                marketType={marketType}
                            />
                            <Map
                                go={go}
                                active={activePanel}
                                policy={policy}
                                setStorage={setStorage}
                                openModal={modalHandler}
                                mapOpenFirstTime={mapOpenFirstTime}
                                changeMapOpenFirstTime={changeMapOpenFirstTime}
                                mapCoords={mapCoords}
                                setVpnIsActive={setVpnIsActive}
                                vpnIsActive={vpnIsActive}
                                phone={phone}
                                email={email}
                                vk_user_id={vk_user_id}
                                setActivePanel={setActivePanel}
                                openAuthPopup={openAuthPopup}
                                setMapCoords={setMapCoords}
                                changeSelectedCityName={changeSelectedCityName}
                                changeSelectedCityKey={changeSelectedCityKey}
                                userName={userName}
                                userSurname={userSurname}
                                searchParams={searchParams}
                                changeMarketType={changeMarketType}
                                marketType={marketType}
                                id="map"/>
                            <Cities
                                go={go}
                                active={activePanel}
                                policy={policy}
                                setStorage={setStorage}
                                openModal={modalHandler}
                                mapOpenFirstTime={mapOpenFirstTime}
                                changeMapOpenFirstTime={changeMapOpenFirstTime}
                                setVpnIsActive={setVpnIsActive}
                                vpnIsActive={vpnIsActive}
                                setMapCoords={setMapCoords}
                                changeSelectedCityName={changeSelectedCityName}
                                changeSelectedCityKey={changeSelectedCityKey}
                                phone={phone}
                                email={email}
                                vk_user_id={vk_user_id}
                                setActivePanel={setActivePanel}
                                openAuthPopup={openAuthPopup}
                                userName={userName}
                                userSurname={userSurname}
                                searchParams={searchParams}
                                changeMarketType={changeMarketType}
                                marketType={marketType}
                                id="cities"/>
                            <Catalog
                                go={go}
                                active={activePanel}
                                policy={policy}
                                openModal={modalHandler}
                                selectedCatalogID={selectedCatalogID}
                                selectedCityKey={selectedCityKey}
                                actualCatalogCard={actualCatalogCard}
                                changeSelectedCatalogID={changeSelectedCatalogID}
                                changeActualCatalogCard={changeActualCatalogCard}
                                setActivePanel={setActivePanel}
                                selectedCategoryID={selectedCategoryID}
                                marketType={marketType}
                                id="catalog"/>
    
                            <Coupon
                                go={go}
                                active={activePanel}
                                policy={policy}
                                openModal={modalHandler}
                                selectedCatalogID={selectedCatalogID}
                                selectedCityKey={selectedCityKey}
                                actualCatalogCard={actualCatalogCard}
                                changeSelectedCatalogID={changeSelectedCatalogID}
                                changeActualCatalogCard={changeActualCatalogCard}
                                setActivePanel={setActivePanel}
                                selectedCategoryID={selectedCategoryID}
                                changeMarketType={changeMarketType}
                                marketType={marketType}
                                id="coupon"/>
                            <Prizes
                                active={activePanel}
                                go={go}
                                id="prizes"
                            />
                            <Notifications
                                active={activePanel}
                                go={go}
                                pushText={pushText}
                                userID={userID}
                                searchParams={searchParams}
                                id="notifications"
                            />
                            <BindCard
                                go={go}
                                active={activePanel}
                                id="bind-card"
                            />
                            <Profile
                                go={go}
                                fName={userName}
                                lName={userSurname}
                                city={selectedCityName}
                                photo={userPhoto}
                                active={activePanel}
                                id="profile"
                            />
                            <PersonalData
                                go={go}
                                active={activePanel}
                                id="personal-data"
                            />
                            <Rules
                                active={activePanel}
                                go={go}
                                id="rules"
                            />
                            <Scan
                                go={go}
                                active={activePanel}
                                id='scan'
                                openModal={modalHandler}
                            />
                            
                            
                            <Help
                                go={go}
                                active={activePanel}
                                openModal={modalHandler}
                                notificationsIsEnabled={notificationsIsEnabled}
                                setNotificationsIsEnabled={setNotificationsIsEnabled}
                                id='help'
                            />
                            <History
                                go={go}
                                active={activePanel}
                                id='history'
                            />
                            <Actions
                                go={go}
                                active={activePanel}
                                id='actions'
                            />
                        </View>
                    </Root>
                </SplitCol>
            
            </SplitLayout>
        </AppRoot>
    );
};

export default App