import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {
    Text,
    Div,
    Group,
    Panel,
    PanelHeader,
    PanelHeaderBack
} from "@vkontakte/vkui";

const Rules = ({ activePanel, go }) => {
    return (
        <Panel >
            <PanelHeader
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='profile'
                    />
                }>Правила</PanelHeader>
            <Group>
                <Div>
                    <Text weight="semibold">Полные правила акции</Text>
                    <Text weight="regular" style={{ marginBottom: 16 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus et nibh eget in urna elit facilisi leo euismod. Varius adipiscing facilisis rhoncus lectus metus, interdum consequat. Ac lorem faucibus eget dui tempor porttitor urna. Metus, sed est, sit justo, tortor in placerat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus et nibh eget in urna elit facilisi leo euismod. Varius adipiscing facilisis rhoncus lectus metus, interdum consequat. Ac lorem faucibus eget dui tempor porttitor urna. Metus, sed est, sit justo, tortor in placerat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus et nibh eget in urna elit facilisi leo euismod. Varius adipiscing facilisis rhoncus lectus metus, interdum consequat. Ac lorem faucibus eget dui tempor porttitor urna. Metus, sed est, sit justo, tortor in placerat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus et nibh eget in urna elit facilisi leo euismod. Varius adipiscing facilisis rhoncus lectus metus, interdum consequat. Ac lorem faucibus eget dui tempor porttitor urna. Metus, sed est, sit justo, tortor in placerat.

                    </Text>
                    <Text weight="regular" style={{ marginBottom: 16 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus et nibh eget in urna elit facilisi leo euismod. Varius adipiscing facilisis rhoncus lectus metus, interdum consequat. Ac lorem faucibus eget dui tempor porttitor urna. Metus, sed est, sit justo, tortor in placerat.
                    </Text>

                </Div>
            </Group>
            <TabbarLenta
                active={activePanel}
                go={go}
            ></TabbarLenta>
        </Panel>
    )
}

export default Rules