import './Modals.css'
import {
    ModalRoot,
    Button,
    ModalCard,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    Div,
    Text,
    Spacing,
    Link,
    Group,
    SimpleCell,
    GridAvatar
} from '@vkontakte/vkui';
import {useState, useEffect} from 'react';
import {Icon24Cancel, Icon24ExternalLinkOutline, Icon24MailOutline} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import Card from "../Card/Card";
import product3 from "../Card/img/3.png";
import logo from "./img/logo.svg";
import couponImg from "./img/coupon.svg";
import iconPerson from "./img/person.svg";
import iconEmail from "./img/email.svg";
import iconPhone from "./img/phone.svg";
import iconInfo from "./img/info.svg";
import logoIcon from "./img/logoIcon.svg";
import locatorIcon from "./img/cityLocator.svg";
import {default as axios} from "axios";
import queryString from "query-string";

const Modals = ({
                    onClose,
                    activeModal,
                    go,
                    openModal,
                    photo,
                    modalData,
                    setSelectedMarket,
                    changeMapOpenFirstTime,
                    changeSelectedCityKey,
                    phone,
                    setPhone,
                    email,
                    setEmail,
                    vk_user_id,
                    selectedCityKey
                }) => {

    
    const goHandler = (e) => {
        onClose(null)
        go(e)
    }
    
    return (
        <ModalRoot
            activeModal={activeModal}
            onClose={() => {
                onClose(null)
                
            }}
        >
            
            {/*Товар в каталоге*/}
            <ModalPage
                header={
                    <ModalPageHeader
                        before={
                            <div></div>
                        }
                        after={
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                onClose(null, modalData)
                            }}>
                                <Icon24Cancel/>
                            </PanelHeaderButton>
                        }
                    
                    >
                        {modalData && modalData.categoryName ? `Каталог «${modalData.categoryName}»` : 'Все товары'}
                    </ModalPageHeader>
                }
                id="catalog_info"
            >
                {
                    modalData && modalData.url ?
                        <Div>
                            <Card
                                modal={true}
                                type={'big'}
                                image={modalData.image}
                                price={modalData.price}
                                oldPrice={modalData.priceOld}
                                sale={modalData.discount}
                                text={modalData.title}
                            />
                            <Spacing size={12}/>
                            <Button
                                size='l'
                                href={modalData.url}
                                target={'_blank'}
                                modalData
                                appearance={'accent'}
                                stretched
                                after={<Icon24ExternalLinkOutline aria-hidden />}
                            >Купить в «Ленте»</Button>
                            <Spacing size={16}/>
                        </Div>
                        :
                        <Text>Нет данных</Text>
                }
            
            </ModalPage>
    
    
            {/*Товар в купонах*/}
            <ModalPage
                header={
                    <ModalPageHeader
                        before={
                            <div></div>
                        }
                        after={
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                onClose(null, modalData)
                            }}>
                                <Icon24Cancel/>
                            </PanelHeaderButton>
                        }
            
                    >
                        </ModalPageHeader>
                }
                id="coupon_info"
            >
                {
                    modalData && modalData.coupon ?
                        <Div>
                            <Card
                                modal={true}
                                type={'coupon'}
                                imageLarge={true}
                                image={modalData.detail_img}
                                date={modalData.discount_date}
                                detail_text={modalData.detail_text}
                                sale={modalData.discount}
                                text={modalData.name}
                                coupon={modalData.coupon}
                            />
                       
                            <Spacing size={16}/>
                        </Div>
                        :
                        <Text>Нет данных</Text>
                }
    
            </ModalPage>
            
            {/*Попап с выбором магазина на карте*/}
            <ModalPage
                header={
                    <ModalPageHeader
                        after={
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                onClose(null)
                            }}>
                                <Icon24Cancel/>
                            </PanelHeaderButton>
                        }
                    >
                        {modalData && modalData.cityTitle ? modalData.cityTitle : null}
                    </ModalPageHeader>
                }
                id="shop"
                // onClose={() => {
                //     // setSelectedMarket(modalData.cityTitle + ', ' + modalData.cityDescr)
                //     // goHandler(e)
                //     //
                //     // changeSelectedCityKey(modalData.cityKey)
                // }}

                onClose={() => {
                    onClose(null)
                }}
            >
                
                <Div>
                    <Text
                        style={{color: '#818C99'}}> {modalData && modalData.cityDescr ? modalData.cityDescr : null}</Text>
                    <Spacing size={35}/>
                    {/*<Button*/}
                    {/*    size='l'*/}
                    {/*    onClick={(e) => {*/}
                    {/*       */}
                    {/*    }}*/}
                    {/*    data-to='main'*/}
                    {/*    aria-label={'Выбрать'}*/}
                    {/*    appearance={'accent'}*/}
                    {/*    stretched*/}
                    {/*>Выбрать</Button>*/}
                    <Spacing size={16}/>
                </Div>
            </ModalPage>
            
            {/*Попап согласия с условиями*/}
            <ModalPage
                header={
                    <ModalPageHeader
                        
                        after={
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                onClose(null)
                            }}>
                                <Icon24Cancel/>
                            </PanelHeaderButton>
                        }
                    
                    >
                        Уведомление об условиях
                    </ModalPageHeader>
                }
                id="agreement"
            >
                
                <Div dangerouslySetInnerHTML={{__html: modalData && modalData.rules ? modalData.rules : null}}/>
            </ModalPage>
            
            
            {/*Попап согласия с персональными данными*/}
            <ModalPage
                header={
                    <ModalPageHeader
                        
                        after={
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                {modalData && modalData.disableStartPopup ?  onClose(false) : onClose('start')}
                                
                            }}>
                                <Icon24Cancel/>
                            </PanelHeaderButton>
                        }
                    
                    >
                        Политика персональных данных
                    </ModalPageHeader>
                }
                id="politics"
            >
                
                <Div>
                    <Text style={{
                        color: '#000000',
                        fontSize: '17px',
                        fontWeight: 'bold'
                    }}>ПОЛИТИКА ООО «ЛЕНТА» В ОБЛАСТИ ОБРАБОТКИ
                        И ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</Text>
                    <Spacing size={10}/>
                    <Text style={{color: '#000000', fontSize: '15px', fontWeight: 'bold'}}>ЦЕЛЬ ПОЛИТИКИ:</Text>
                    <Spacing size={4}/>
                    <Text style={{
                        color: '#000000',
                        fontSize: '15px'
                    }}>ОБЕСПЕЧЕНИЕ ЗАЩИТЫ ПРАВ И СВОБОД ЧЕЛОВЕКА И ГРАЖДАНИНА (СУБЪЕКТА ПДн), В ТОМ ЧИСЛЕ ЗАЩИТЫ ПРАВ НА НЕПРИКОСНОВЕННОСТЬ ЕГО ЧАСТНОЙ ЖИЗНИ, ЛИЧНУЮ И СЕМЕЙНУЮ ТАЙНУ, ПРИ ОБРАБОТКЕ ЕГО ПЕРСОНАЛЬНЫХ ДАННЫХ (ПДн) В ООО «ЛЕНТА» В СООТВЕТСТВИИ С ЗАКОНОДАТЕЛЬСТВОМ РФ.
                    </Text>
                    <Spacing size={8}/>
                    <Text style={{color: '#000000', fontSize: '15px', fontWeight: 'bold'}}>УСЛОВИЯ ОБРАБОТКИ:</Text>
                    <Spacing size={4}/>
                    <Text style={{color: '#000000', fontSize: '15px'}}>
                        Обработка ПДн осуществляется с соблюдением принципов и правил, предусмотренных нормативно-правовыми актами в области ПДн
                        <br/>Обработка ПДн осуществляется с согласия субъекта ПДн или без такого согласия в случаях, предусмотренных федеральным законом
                        <br/>Запись, систематизация, накопление, хранение, уточнение, извлечение ПДн граждан РФ при сборе ПДн осуществляются с использованием баз данных, находящихся на территории РФ, если иное не предусмотрено законодательством
                        <br/>Трансграничная передача ПДн осуществляется только после сбора ПДн на территории РФ в соответствии с законодательством
                        <br/>Лица, получившие доступ к ПДн, обязаны сохранять конфиденциальность и не распространять их без согласия субъекта ПДн, если иное не предусмотрено федеральным законом
                    </Text>
                    <Spacing size={8}/>
                    <Text style={{color: '#000000', fontSize: '15px', fontWeight: 'bold'}}>ПРИНЦИПЫ ОБРАБОТКИ:</Text>
                    <Spacing size={4}/>
                    <Text style={{color: '#000000', fontSize: '15px'}}>
                        Соблюдение прав субъектов ПДн при обработке их ПДн
                        <br/>Осуществление обработки ПДн на законной и справедливой основе для достижения целей их обработки
                        <br/>Недопущение обработки ПДн, которые: не отвечают целям обработки, избыточны или содержатся в базах данных, цели обработки которых несовместимы
                        <br/>Обеспечение точности, достаточности и актуальности ПДн по отношению к целям их обработки
                        <br/>Хранение ПДн в форме, позволяющей определить субъекта ПДн, не дольше, чем этого требуют цели обработки ПДн
                        <br/>Уничтожение или обезличивание ПДн по достижении целей обработки или в случае утраты необходимости в достижении этих целей.
                    </Text>
                    <Spacing size={8}/>
                    <Text
                        style={{color: '#000000', fontSize: '15px', fontWeight: 'bold'}}>СПОСОБЫ ДОСТИЖЕНИЯ ЦЕЛИ:</Text>
                    <Spacing size={4}/>
                    <Text style={{color: '#000000', fontSize: '15px'}}>
                        Обеспечение защищенности информационных систем ПДн от воздействия актуальных угроз безопасности ПДн с учетом оценки вреда субъектам ПДн
                        <br/>Применение комплекса организационных и технических мер по обеспечению безопасности ПДн, необходимых для выполнения требований законодательства к защите ПДн, при их обработке в информационных системах ПДн, а также без использования средств автоматизации
                        <br/>Систематический контроль соответствия обработки ПДн требованиям законодательства и локальным нормативным актам
                        <br/>Проведение мероприятий по информированию и обучению персонала правилам обработки и защиты ПДн
                        <br/>Неотвратимость наступления ответственности за нарушение настоящей Политики.
                    </Text>
                </Div>
            </ModalPage>
            
            {/*Стартовый попап*/}
            <ModalCard
                id={'start'}
                onClose={() => {
                    console.log('onClose')
                }}
                icon={<div>
                    <img src={logo}/>
                </div>}
                header="Добро пожаловать"
                subheader={<div className={'StartModal'}>
                    Нажимая «Запустить», вы принимаете <Link onClick={() => {
                    openModal('politics')
                }}>пользовательское соглашение</Link> и <Link onClick={() => {
                    openModal('politics')
                }}>политику конфиденциальности</Link>.
                    <Spacing size={10}/>
                    <Link onClick={() => {
                        openModal('info')
                    }}>Передаваемые данные</Link>
                </div>}
                actions={
                    <Button
                        size="l"
                        mode="primary"
                        onClick={() => {
                           
                            // if (phone === null) {
                            //     bridge
                            //         .send('VKWebAppGetEmail')
                            //         .then(data => {
                            //             // Обработка события  в случае успеха.
                            //             console.log(data);
                            //             setEmail(data.email)
                            //         })
                            //         .catch(error => {
                            //             console.log(error);
                            //             // Обработка события в случае ошибки.
                            //         });
                            // }
                            //
                            // if (email === null) {
                            //     bridge
                            //         .send('VKWebAppGetPhoneNumber')
                            //         .then(data => {
                            //             // Обработка события  в случае успеха.
                            //             console.log(data);
                            //             setPhone(data.phone_number)
                            //         })
                            //         .catch(error => {
                            //             console.log(error);
                            //             // Обработка события в случае ошибки.
                            //         });
                            // }
    
    
    
    
                            onClose(null)
    
                            
                            
                           
                        }}
                    >
                        Запустить
                    </Button>
                }
            />
            
            {/*Попап с персональными данными*/}
            <ModalCard
                id={'info'}
                onClose={() => {
                    openModal('start')
                }}
                
                
                actions={
                    <div className={'InfoModal'}>
                        <div className={'InfoModal_close'}>
                            <PanelHeaderButton aria-label={'закрыть'} onClick={() => {
                                onClose('start')
                            }}>
                                <Icon24Cancel color={'#818C99'}/>
                            </PanelHeaderButton>
                        </div>
                        <div className={'InfoModal_top'}>
                            <div className={'InfoModal_avatar'}>
                                <GridAvatar src={[photo]}/>
                            </div>
                            
                            <div className={'InfoModal_avatarLenta'}>
                                <GridAvatar src={[logoIcon]}/>
                            </div>
                        </div>
                        <p className={'InfoModal_text'}>Приложение <b>«Лента»</b> получит:</p>
                        <div className={'InfoModal_content'}>
                            <Group>
                                <SimpleCell
                                    className={'InfoModal_cell'}
                                    before={<img className={'InfoModal_icon'} src={iconPerson}/>}
                                    disabled
                                >
                                    ФИО
                                </SimpleCell>
                                <SimpleCell
                                    className={'InfoModal_cell'}
                                    before={<img className={'InfoModal_icon'} src={iconEmail}/>}
                                    disabled
                                >
                                    Email
                                </SimpleCell>
                                <SimpleCell
                                    className={'InfoModal_cell'}
                                    before={<img className={'InfoModal_icon'} src={iconPhone}/>}
                                    disabled
                                >
                                    Ваш номер телефона
                                </SimpleCell>
                                <SimpleCell
                                    className={'InfoModal_rules'}
                                    before={<img className={'InfoModal_icon'} src={iconInfo}/>}
                                    disabled
                                >
                                    <Link onClick={() => {
                                        openModal('politics')
                                    }}>Пользовательское соглашение</Link>
                                    <br/>
                                    <Link onClick={() => {
                                        openModal('politics')
                                    }}>Политика конфиденциальности</Link>
                                </SimpleCell>
                            </Group>
                        </div>
                    </div>
                    
                }
            />
            
            {/*Попап с выбором города*/}
            <ModalCard
                id={'city'}
                onClose={() => onClose(null)}
                data-to='main'
                icon={<img src={locatorIcon}/>}
                header={modalData && modalData.cityName ? modalData.cityName : null}
                subheader="Ваш город"
                actions={
                    <Div className={'CityPopup'}>
                        <Button
                            size="s"
                            mode="secondary"
                            onClick={() => onClose(null)}
                        >
                            Выбрать другой
                        </Button>
                        <Button
                            size="s"
                            mode="primary"
                            data-to='main'
                            onClick={
                               
                                (e) => {
                                    if (modalData && modalData.redirectToCatalog) {
                                        goHandler(e)
                                    }
                                    changeMapOpenFirstTime(false)
                                    changeSelectedCityKey(modalData.cityKey)
                                    onClose(null)
                                }}
                        >
                            Да, верно
                        </Button>
                    </Div>
                }
            />
    
    
    
    
    
            {/*Попап с VPN*/}
            <ModalCard
                id={'vpn'}
                onClose={() => onClose(null)}
                data-to='main'
                icon={<img src={locatorIcon}/>}
                header={"У вас включен VPN?"}
                subheader="Рекомендуем его отключить для стабильной работы приложения. "
                actions={
                    <Button
                        size="m"
                        stretched
                        onClick={() => onClose(null)}
                    >
                        Хорошо
                    </Button>
                }
            />
        </ModalRoot>
    )
}

export default Modals