import './Scan.css'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import PanelBannerTop from '../../components/PanelBannerTop/PanelBannerTop';
import QrScanner from 'qr-scanner';


import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
    Group,
    Tabs,
    TabsItem,
    Div,
    FormLayout,
    FormItem,
    Button,
    Input,
    DatePicker,
    CustomSelect,
    FormLayoutGroup
} from '@vkontakte/vkui';
import { useState } from 'react';
import { Icon20CloudArrowUpOutline, Icon24CameraOutline, Icon56MoneyTransferOutline } from '@vkontakte/icons';
import time from '../../data/time'
import bridge from '@vkontakte/vk-bridge';
const Scan = ({ go, active, openModal }) => {
    const [activeTab, setActiveTab] = useState('scan-tab')
    const [qrData, setQrData] = useState('');
    const [hidden, setHidden] = useState(false)



    const qrHandler = () => {
        bridge
            .send("VKWebAppOpenCodeReader")
            .then(data => {
                setQrData(data.code_data)
                const resOut = document.querySelector('#res2')
                resOut.innerHTML = 'Результат: ' + data.code_data;
                console.log(data.code_data)

            })
            .catch(error => {
                console.log(error)
            });
    }

    const sendCheck = (e) => {
        openModal('card-success')
    }

    const qrtest = () => {
        console.log('test')
        setHidden(true)
        const videoEl = document.querySelector('#video');
        const qrScanner = new QrScanner(videoEl, result => qrStop(result, qrScanner));
        qrScanner.start();
    }

    const qrStop = (result, qrScanner) => {
        const resOut = document.querySelector('#res')
        console.log(result)
        resOut.innerHTML = 'Результат: ' + result;
        qrScanner.stop();
        setHidden(false)
    }
    const test = () => {
        console.log('test')
    }

    return (
        <Panel id="scan">
            <PanelHeader
                transparent={true}
                separator={false}
                visor={false}
                className='PanelHeader__white-text'
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='profile'

                    />
                }>Карта №1</PanelHeader>

            <TabbarLenta
                active={active}
                go={go}
            ></TabbarLenta>
            <PanelBannerTop
                bg={'BindBg'}
            ></PanelBannerTop>

            <Group style={{ paddingBottom: '80px' }}>
                <Tabs>
                    <TabsItem
                        selected={activeTab === 'scan-tab'}
                        onClick={() => { setActiveTab('scan-tab') }}
                    >
                        Загрузить<br /> фото
                    </TabsItem>
                    <TabsItem
                        selected={activeTab === 'form-tab'}
                        onClick={() => { setActiveTab('form-tab') }}
                    >
                        Ввести<br /> вручную
                    </TabsItem>
                    <TabsItem
                        selected={activeTab === 'qr-tab'}
                        onClick={() => { setActiveTab('qr-tab') }}
                    >
                        Сканировать <br />QR-код
                    </TabsItem>
                    <TabsItem
                        selected={activeTab === 'qr-test'}
                        onClick={() => { setActiveTab('qr-test') }}
                    >
                        тест
                    </TabsItem>
                </Tabs>
                <Div
                    hidden={!(activeTab === 'scan-tab')}>
                    <div className="scan__add-photo">
                        <div className="scan__add-block"
                            onClick={test}>
                            <Icon20CloudArrowUpOutline
                                width={24} height={24}
                                style={{ color: 'black' }}
                            />
                        </div>
                        <Button
                            style={{ width: '249px', margin: '10px auto 0' }}
                            size='l'
                            align='center'
                            onClick={sendCheck}
                            data-modal='card-success'
                        >Отправить</Button>
                    </div>

                </Div>
                <Div
                    hidden={!(activeTab === 'form-tab')}

                >
                    <FormLayout>
                        <FormItem
                            top="Дата"
                            status={'default'}
                        >

                            <DatePicker
                                min={{ day: 1, month: 1, year: 2021 }}

                                onDateChange={(value) => {
                                    console.log(value);
                                }}
                                dayPlaceholder="ДД"
                                monthPlaceholder="ММММ"
                                yearPlaceholder="ГГГГ"
                            />
                        </FormItem>
                        <FormLayoutGroup mode="horizontal">
                            <FormItem top="Время">
                                <CustomSelect placeholder="Часы" options={time['hours']} />
                            </FormItem>
                            <FormItem top="время" className='FormItem__top-transparent'>
                                <CustomSelect placeholder="Минуты" options={time['minutes']} />
                            </FormItem>
                        </FormLayoutGroup>
                        <FormItem
                            top="Итого"
                            status={'default'}
                        >
                            <Input
                                type="text"
                                name="total"
                            />
                        </FormItem>
                        <FormItem
                            top="ФН: №"
                            status={'default'}
                        >
                            <Input
                                type="text"
                                name="fn"
                            />
                        </FormItem>
                        <FormItem
                            top="ФД: №"
                            status={'default'}
                        >
                            <Input
                                type="text"
                                name="fd"
                            />
                        </FormItem>
                        <FormItem
                            top="ФПД или ФП: №"
                            status={'default'}
                        >
                            <Input
                                type="text"
                                name="fpd"
                            />
                        </FormItem>
                        <FormItem
                            style={{ paddingBottom: '0px' }}
                        >
                            <Button
                                mode='tertiary'
                                style={{ color: 'black' }}
                                stretched={true}
                            >Где найти эти данные?</Button>

                        </FormItem>
                        <FormItem>
                            <Button
                                stretched={true}
                                size='l'
                            >Отправить</Button>
                        </FormItem>
                    </FormLayout>
                </Div>
                <Div
                    hidden={!(activeTab === 'qr-tab')}>

                    <div className="scan__add-photo">
                        <div
                            className="scan__add-block"
                            onClick={qrHandler}
                        >
                            <Icon24CameraOutline
                                width={24} height={24}
                                style={{ color: 'black' }}
                            />
                        </div>
                        <span id='res2'></span>
                        <Button
                            style={{ width: '249px', margin: '10px auto 0' }}
                            size='l'
                            align='center'
                        >Отправить</Button>
                    </div>
                </Div>
                <Div
                    hidden={!(activeTab === 'qr-test')}>

                    <div className="scan__add-photo">
                        <div
                            className={hidden ? 'scan__add-block hidden' : 'scan__add-block'}
                            onClick={qrtest}
                        >
                            <Icon24CameraOutline
                                width={24} height={24}
                                style={{ color: 'black' }}
                            />
                        </div>
                        <video className={!hidden ? 'scan__video hidden' : 'scan__video'} id='video'></video>
                        <span id='res'></span>
                        <Button
                            style={{ width: '249px', margin: '10px auto 0' }}
                            size='l'
                            align='center'
                        >Отправить</Button>
                    </div>
                </Div>
            </Group>



        </Panel>
    )

}


export default Scan