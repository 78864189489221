import './Help.css'
import React from "react";
import bridge from '@vkontakte/vk-bridge';
import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
    Group,
    IconButton,
    SimpleCell,
    MiniInfoCell,
    Avatar,
    List,
    Cell,
    Switch,
    Link,
    Title,
    Div,
    Spacing,
    Separator,
    Text
} from '@vkontakte/vkui';
import {Icon24MessageOutline, Icon28ChevronBack, Icon24ErrorCircleOutline} from '@vkontakte/icons';

import TabbarLenta from '../../components/TabbarLenta/TabbarLenta';
import vkImg from './img/vk.svg';
import okImg from './img/ok.svg';
import tgImg from './img/tg.svg';
import dzImg from './img/dz.svg';

import plImg from './img/pl.svg';
import msImg from './img/ms.svg';
import tlImg from './img/tl.svg';


const Help = ({go, active, openModal, notificationsIsEnabled, setNotificationsIsEnabled}) => {
    return (
        
        <Panel className={'Help'}>
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={go}
                            data-to='main'
                        />
                    </IconButton>
                    
                }>Помощь</PanelHeader>
            <TabbarLenta
                active={active}
                go={go}
            ></TabbarLenta>
            <Group>
                <Div>
                    <Title level="2" style={{marginBottom: '-5px'}}>
                        Полезные ссылки
                    </Title>
                </Div>
                <List>
                    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 0}}><Avatar size={24}
                                                                                     src={plImg}/></IconButton>}
                        mode="more"
                    >
                        <Link href={"https://lenta.com/"} target={'_blank'}>Сайт lenta.com</Link>
                    </MiniInfoCell>
                    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 0}}><Avatar size={24}
                                                                                     src={msImg}/></IconButton>}
                        mode="more"
                    >
                        <Link href={"https://lenta.com/kontakty/"} target={'_blank'}>Поддержка «Ленты» </Link>
                    </MiniInfoCell>
                    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}
                                                                                     src={vkImg}/></IconButton>}
                        mode="more"
                    >
                        <Link href={"https://vk.com/lentacom"} target={'_blank'}>«Лента» в VK</Link>
                    </MiniInfoCell>
                    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}
                                                                                     src={okImg}/></IconButton>}
                        mode="more"
                    >
                        <Link href={"https://ok.ru/lentacom"} target={'_blank'}>«Лента» в Одноклассниках</Link>
                    </MiniInfoCell>
                    
                    {/*<MiniInfoCell*/}
                    {/*    before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}*/}
                    {/*                                                                 src={tgImg}/></IconButton>}*/}
                    {/*    mode="more"*/}
                    {/*>*/}
                    {/*    <a href={"tg://t.me/Lentacom"} target={'_blank'}>«Лента» в Telegram (tg://t.me/Lentacom)</a>*/}
                    {/*</MiniInfoCell>*/}
    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}
                                                                                     src={tgImg}/></IconButton>}
                        mode="more"
                    >
                        <a href={"https://t.me/Lentacom"} target={'_blank'} className={'vkuiLink'}>«Лента» в Telegram</a>
                    </MiniInfoCell>
    
                    {/*<MiniInfoCell*/}
                    {/*    before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}*/}
                    {/*                                                                 src={tgImg}/></IconButton>}*/}
                    {/*    mode="more"*/}
                    {/*>*/}
                    {/*    <a href={"tg://resolve?domain=Lentacom"}>«Лента» в Telegram (tg://resolve?domain=Lentacom)</a>*/}
                    {/*</MiniInfoCell>*/}
                    
                    <MiniInfoCell
                        before={<IconButton style={{height: 20, padding: 2}}><Avatar size={20}
                                                                                     src={dzImg}/></IconButton>}
                        mode="more"
                    >
                        <Link href={"https://zen.yandex.ru/id/60b0f40353c7205b4406311b"}
                              target={'_blank'}>«Лента» в Дзен</Link>
                    </MiniInfoCell>
                
                
                </List>
                <Spacing size={24}>
                    <Separator/>
                </Spacing>
                
                <Div>
                    <Title level="2" style={{marginBottom: '-5px'}}>
                        Центр поддержки клиентов
                    </Title>
                </Div>
                <Spacing size={24}>
                    <Separator/>
                </Spacing>
                <SimpleCell class={'Help_notifications'} Component="label" after={<Switch checked={notificationsIsEnabled} onChange={(e) => {
                
                    
                    
                    if (
                        e.target.checked === false
                    ) {
                        bridge.send('VKWebAppDenyNotifications')
                            .then((data) => {
                                if (data.result) {
                                    console.log(data.result);
                                    setNotificationsIsEnabled(false)
                                }
                            })
                            .catch((error) => {
                                // Ошибка
                                console.log(error);
                            });
                    }
                    
                    else {
                        bridge.send("VKWebAppAllowNotifications")
                            .then((data) => {
                                if (data.result) {
                                    console.log(data.result);
                                    setNotificationsIsEnabled(true)
                                    // Уведомления от мини-приложения или игры отключены
                                }
                            })
                            .catch((error) => {
                                // Ошибка
                                console.log(error);
                            });
                    }
                    console.dir(e.target)
                }} />}>
                    Включить уведомления
                </SimpleCell>
                
                <Spacing size={24}>
                    <Separator/>
                </Spacing>
    
               
                <MiniInfoCell
                    before={<IconButton style={{height: 20, padding: 0}}><Avatar size={24} src={tlImg}/></IconButton>}
                    mode="more"
                >
                    <a href={"tel:+78007004111"} className={'vkuiLink'} target={'_blank'}>8 800 700 4 111</a>
                </MiniInfoCell>
                <Spacing size={24}>
                    <Separator/>
                </Spacing>
                
                <Div>
                    <Text>ООО «Лента» <br/>Адрес 197374 г. Санкт-Петербург, <br/>ул. Савушкина, д.112, Литера Б <br/>ИНН 7814148471 · ОГРН 1037832048605</Text>
                    <Spacing size={25}/>
                    <Text><Link className={'Help_rules'} onClick={() => {
                        openModal('politics', {
                            disableStartPopup: true
                        })
                    }}>Политика конфиденциальности</Link></Text>
                </Div>
    
                <Spacing size={85}>
                
                </Spacing>
            </Group>
        </Panel>
    
    )
}

export default Help