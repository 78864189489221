import './PanelBannerTop.css'
const PanelBannerTop = ({ bg }) => {
    const style = {
        //backgroundImage: `url(${bg})`
    }
    return (
        <div className="PanelBannerTop" style={style}>

        </div>
    )
}

export default PanelBannerTop