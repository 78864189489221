import './Catalog.css'
import React from "react";
import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Spacing,
    Select,
    CustomSelect,
    CustomSelectOption,
    Text,
    Title,
    Separator,
    HorizontalScroll,
    Counter,
    PanelHeaderButton,
    ModalRoot,
    ModalPage,
    ModalPageHeader, ScreenSpinner, IconButton
} from "@vkontakte/vkui";
import {Icon24Cancel, Icon28ChevronBack} from '@vkontakte/icons';
import {Icon24ChevronDown} from '@vkontakte/icons';
import {Icon24ChevronUp} from '@vkontakte/icons';
import MainBlock from '../../components/MainBlock/MainBlock'

import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {useEffect, useState} from 'react';
import Card from "../../components/Card/Card";
import {default as axios} from "axios";
import createCard from "../../functions/createCard";
import BlockEmpty from "../../components/BlockEmpty/BlockEmpty";

const Catalog = ({go, active, openModal, selectedCityKey, selectedCategoryID, marketType, selectedCatalogID, actualCatalogCard, changeSelectedCatalogID, changeActualCatalogCard}) => {
    const [data, setData] = useState(null);
    const [tabsOpened, toggleTabsOpened] = useState(false);
    
    const [catalogCard, setCatalogCard] = useState(null);
    
    const [tabsCount, changeTabsCount] = useState(5);
    const allCategory = {
        title: 'Все товары',
        id: 0
    }
    
    
    const selectOptions = [
        {
            label: "По цене (по возрастанию)",
            value: 'price_top',
        },
        {
            label: "По цене (по убыванию)",
            value: 'price_bottom',
        },
        {
            label: "По размеру скидки",
            value: 'sale',
        }
    ];
    const [selectType, setSelectType] = React.useState(selectOptions[0].value);
    
    const [activeCategory, setActiveCategory] = useState(allCategory);
    
    useEffect(() => {
        let category = '';
        let address = 'feeds';
        if (selectedCatalogID && selectedCatalogID !== 'all') {
            category = `&category_id=${selectedCatalogID}`
            address = 'feed'
        }
    
    
        let url = window.location.origin;
        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0|| url.indexOf('vk-apps') >= 0) {
            url = 'https://devvkminiapp.lenta.com'
        }
        
        axios.get(url + `/api/${address}?city_key=${selectedCityKey}${category}&market_type=${marketType}`)
            .then(function (response) {
                // обработка успешного запроса
                const data = response.data;
                
                if (data.category) {
                    data.category.unshift(allCategory)
                }
    
                if (data) {
                    setData(data)
                }
    
                else {
                    setData({
                        error: 'Empty'
                    })
                }
    
                let selectedCategory = allCategory;
                if (selectedCategoryID !== 0 && data && data.category) {
                    data.category.forEach(item => {
                        if (item.id === selectedCategoryID) {
                            selectedCategory = {
                                title: item.title,
                                id: selectedCategoryID
                            }
    
                            setActiveCategory(selectedCategory)
                        }
            
                    })
        
                }
    
                if (data.catalog) {
                    setCatalogCard(data.catalog)
                }
                
                
                
                console.log(response);
            })
            .catch(function (error) {
                // обработка ошибки
                console.log(error);
                setData({
                    error: error.message
                })
                
            })
        
    }, [])
    
    
    let content = <Div style={{position: 'relative', zIndex: 10}}>
        <Spacing size={50}/>
        <ScreenSpinner state="loading" id={'catalog-spinner'}/>
    </Div>
    
    if (data && data.error) {
        content =
            <BlockEmpty
                changeActualCatalogCard={changeActualCatalogCard}
                changeSelectedCatalogID={changeSelectedCatalogID}
                go={go}
                type={'catalog'}
            />
    } else if (data && data.category && data.items) {
        
        
        const categories = data.category;
        console.log('categories', categories)
        
        let tabsElems = categories.map((item, index) => {
            return (
                <li className={'Catalog_category'} key={item.id}>
                    <Button
                        size='s'
                        onClick={() => {
                            setActiveCategory(item)
                        }}
                        hasActive={false}
                        appearance={item.id === activeCategory.id ? 'accent' : 'neutral'}
                    >{item.title}</Button>
                </li>
            )
        })
        
        
        const items = data.items;
        let selectedItems = [];
        if (+activeCategory.id !== 0) {
            selectedItems = items.filter(item => +item.categoryId === +activeCategory.id);
        } else {
            selectedItems = items;
        }
        
        console.log('selectedItems', selectedItems)
        
        let itemsElems = [];
        
        if (Array.isArray(selectedItems) && selectedItems.length) {
            itemsElems = selectedItems.sort((elem1, elem2) => {
                console.log('selectType', selectType)
                
                if (selectType === 'price_top') {
                    return +elem1.price - +elem2.price
                }
                
                else if (selectType === 'price_bottom') {
                    return +elem2.price - +elem1.price
                }

                else if (selectType === 'sale') {
                    return +elem2.discount.substr(1, 2) - +elem1.discount.substr(1, 2)
                }
            })
            itemsElems = itemsElems.map((item, index) => {
                return (
                    <li className={'Catalog_card'} key={item.title + index} onClick={() => {
                        openModal('catalog_info', {...item, categoryName: catalogCard ? catalogCard.name : null})
                    }}>
                        <Card
                            type={'small'}
                            image={item.image}
                            price={item.price}
                            oldPrice={item.priceOld}
                            sale={item.discount}
                            text={item.title}
                            url={item.url}
                        />
                    </li>
                )
            })
        }
        
        else {
            itemsElems = <BlockEmpty
                changeActualCatalogCard={changeActualCatalogCard}
                changeSelectedCatalogID={changeSelectedCatalogID}
                go={go}
                type={'catalog'}
            />
        }
        
    
        tabsElems = tabsElems.slice(0, tabsCount)
        

        
        if (!tabsOpened) {
            tabsElems.push(
                <Button
                    size='s'
                    onClick={() => {
                        if (tabsCount + 5 >= categories.length) {
                            toggleTabsOpened(true)
                        }
                        changeTabsCount(tabsCount + 5)
                    }}
                    appearance={'neutral'}
                    after={
                        <Icon24ChevronDown/>
                
                    }
                >
                    Ещё
                </Button>
            )
        }
    
        
        
        console.log('tabsElems ', tabsElems)
        content = <React.Fragment>
            <Div>
                <Title level={'2'}>{catalogCard ? 'Категории' : 'Все товары'}</Title>
                <Spacing size={10}/>
                <ul className={'Catalog_categories'}>
                    {tabsElems}
                </ul>
            
            </Div>
            <Div>
                <Title level={'2'}>{activeCategory.title === 'Все товары' ? 'Все категории' : activeCategory.title}</Title>
                <Spacing size={10}/>
                <Select
                    placeholder="Не выбран"
                    onChange={(e) => setSelectType(e.target.value)}
                    value={selectType}
                    options={selectOptions}
                    renderOption={({ option, ...restProps }) => (
                        <CustomSelectOption
                            {...restProps}
                        />
                    )}
                />
                <Spacing size={10}/>
                <ul className={'Catalog_cards'}>
                    {itemsElems}
                </ul>
            </Div>
        </React.Fragment>
        
    }
    
    console.log('catalogCard', catalogCard)

    
    
    return (
        
        <Panel id="catalog">
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={go}
                            data-to='main'
                        />
                    </IconButton>
                }
            >Лента</PanelHeader>
            <div className={'Catalog'} style={{paddingBottom: 70, paddingTop: 10}}>
                {
                    catalogCard ?
                        
                        <React.Fragment>
                            <Div size="l" className={'Catalog_top'}>
                                <Title level="2" style={{marginBottom: 20}}>
                                    Каталог «{catalogCard.name}»
                                </Title>
                                {createCard(catalogCard, {
                                    rulesLink: true,
                                    isCatalog: true
                                },
                                    go, changeSelectedCatalogID, changeActualCatalogCard, openModal
                                )}
    
                                
                            </Div>
                            
                            <Spacing size={24}>
                                <Separator/>
                            </Spacing>
                        </React.Fragment>
                        
                        :
                        
                        null
                }
                
                
                {content}
            </div>
            <div style={{zIndex: 7}}>
                <TabbarLenta
                    active={active}
                    go={go}
                />
            </div>
        </Panel>
    )
}

export default Catalog