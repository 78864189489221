import './Card.css'
import defaultImage from './img/default.svg';

const Card = ({image, price, oldPrice, sale, text, title, date, detail_text, coupon, imageLarge = false, type, modal}) => {
    

    
    return (
        <div className={`Card Card_${modal ? 'modal' : null}`}>
            <div className={`Card_product ${imageLarge ? 'Card_product_large' : null}`}>
                {sale ? <div className={'Card_sale'}>{sale}</div> : null}
                {image && image !== ''
                    ? <img className={`Card_picture ${imageLarge ? 'Card_picture_large' : null}`} loading="lazy" src={image} alt={text ? text : ''}/>
                    : <img className={'Card_picture'} src={defaultImage} alt={''}/>}
            </div>
            <div className={'Card_info'}>
                <div className={`Card_inner_${type}`}>
                    {date ? <div className={'Card_date'}>{date}</div> : null}
                    {title ? <div className={'Card_title'}>{title}</div> : null}
                    {price ? <div className={'Card_price'}>{price} ₽</div> : null}
                    {oldPrice ? <div className={'Card_priceOld'}>{oldPrice} ₽</div> : null}
                </div>
    
               
              
                {text ? <div className={'Card_name'} dangerouslySetInnerHTML={{__html: text}}/>: null}
    
                {coupon ? <div className={'Card_coupon'} ><img src={coupon}/></div> : null}
                
                {type === 'big' &&
                <div className={'Card_ps'}>
                    Акция может проводиться не во всех магазинах Лента. Информацию об акции уточняйте в магазине.
                    <br/> <br/>
                    Информация на этой странице не является публичной офертой.
                </div>
        
                }
    
                {type === 'coupon' &&
                <div className={'Card_ps' } dangerouslySetInnerHTML={{__html: detail_text}}/>
        
                }
                
               
            </div>
        </div>
    )
}

export default Card