import './Main.css'
import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Tabs,
    TabsItem,
    Spacing,
    Text,
    Title, ScreenSpinner
} from "@vkontakte/vkui";

const axios = require('axios').default;


import MainBlock from '../../components/MainBlock/MainBlock'
import PolicyBanner from '../../components/PolicyBanner/PolicyBanner'
import bg1 from '../../components/MainBlock/img/ex2.jpg'
import bg2 from '../../components/MainBlock/img/ex1.jpg'
import bg3 from '../../components/MainBlock/img/ex3.jpg'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {useEffect, useState} from 'react';
import React from "react";
import {Icon24ChevronCompactRight} from "@vkontakte/icons";
import historyData from "../../data/history.json";
import promoImg from './img/promo.jpg';
import bridge from "@vkontakte/vk-bridge";
import createCard from "../../functions/createCard";
import SwitchMarket from "../../components/SwitchMarket/SwitchMarket";
import BlockEmpty from "../../components/BlockEmpty/BlockEmpty";


const Main = (props) => {
    
    const [data, setData] = useState(null);
    useEffect(() => {
        let url = window.location.origin;
        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0 || url.indexOf('vk-apps') >= 0) {
            url = 'https://devvkminiapp.lenta.com'
        }
        
        if (!data) {
            axios.get(url + `/api/categories?city_key=${props.selectedCityKey}&market_type=${props.marketType}`)
                .then(function (response) {
                    // обработка успешного запроса
                    
    
                    if (response.data && response.data.length) {
                        setData(response.data)
                    }
    
                    else {
                        setData({
                            error: 'Empty'
                        })
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    // обработка ошибки
                    console.log(error);
                    setData({
                        error: error.message
                    })
                })
        }
        
        
    }, [])
    
    
    let content = <ScreenSpinner state="loading" id={'main-spinner'}/>;
    
    if (data && data.error) {
        content = <BlockEmpty
            changeActualCatalogCard={props.changeActualCatalogCard}
            changeSelectedCatalogID={props.changeSelectedCatalogID}
            go={props.go}
            type={'catalog'}
        />
    } else if (data && data.length) {
        const selectedItems = data.filter(item => {
            return item.market_type === props.marketType || item.market_type === 'both'

        })
        
        let cardsElems = []
        if (Array.isArray(selectedItems) && selectedItems.length) {
            cardsElems = selectedItems.map(item => {
                return createCard(item, {
                        isCatalog: false
                    },
                    props.go, props.changeSelectedCatalogID, props.changeActualCatalogCard, props.openModal)
        
            })
        }
        
        else {
            cardsElems = <BlockEmpty
                changeActualCatalogCard={props.changeActualCatalogCard}
                changeSelectedCatalogID={props.changeSelectedCatalogID}
                go={props.go}
                type={'catalog'}
            />
        }
    
        
        
        
        content = <React.Fragment>
            <Div size="l">
                
                {cardsElems}
            
            
            </Div>
            {data.error ? <div/> :
        
                <Div>
                    <Button
                        size='l'
                        onClick={(e) => {
                            props.changeActualCatalogCard(false)
                            props.changeSelectedCatalogID('all')
                            props.go(e)
                    
                        }}
                        data-to={'catalog'}
                        appearance={'accent'}
                        stretched
            
                    >Показать все товары</Button>
        
                </Div>
        
            }
            
            
           
        
        </React.Fragment>
    }
    
    return (
        <Panel id="main">
            <PanelHeader>Лента</PanelHeader>
            <div className={'Main'} style={{paddingBottom: 120, paddingTop: 5}}>
                <Div className={'Main_top'}>
                    {/*<Title level="3" style={{marginBottom: 15}}>*/}
                    {/*    Добро пожаловать в «Ленту»*/}
                    {/*</Title>*/}
                    
                    
                    <Title level="2" style={{marginBottom: 10}}>
                        Каталоги в «Ленте»
                    </Title>
                    <Text style={{fontSize: '15px'}}>
                        Специально для вас собрали самые выгодные предложения по Карте №1 в магазинах «Гипер&nbsp;Лента» и «Супер&nbsp;Лента». Не&nbsp;пропустите!
                    </Text>
                </Div>
                <SwitchMarket marketType={props.marketType} changeMarketType={props.changeMarketType}/>
                {content}
                <Div className={'Main_bottom'}>
                    <Text style={{fontSize: '15px'}}>
                        Ваш город {props.selectedCityName}
                    </Text>
                    <Text style={{fontSize: '13px'}}>
                        <Link onClick={props.go}
                              data-to='cities'>Выбрать другой город
                        </Link>
                    </Text>
                </Div>
            </div>
            <div style={{zIndex: 7}}>
                <TabbarLenta
                    active={props.active}
                    go={props.go}
                />
            </div>
        </Panel>
    )
}

export default Main