import './Prizes.css'
import PanelBannerTop from '../../components/PanelBannerTop/PanelBannerTop'
import prizesBg from '../../components/PanelBannerTop/img/prize-bg.jpg'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {
    Group,
    Div,
    Panel,
    PanelHeader,
    PanelHeaderBack

} from "@vkontakte/vkui";
const Prizes = ({ activePanel, go }) => {

    return (

        <Panel>
            <PanelHeader
                transparent={true}
                separator={false}
                visor={false}
                className='PanelHeader__white-text'
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='main'
                        className='white-back'
                    />
                }
            >Призы</PanelHeader>

            <PanelBannerTop
                bg={prizesBg}
            ></PanelBannerTop>
            <div className="subpage__content">
                <Group>
                    <Div>
                        <h3 className="Prizes__header">Скидки. Подарки. Карты-сюрпризы!*</h3>
                        <p className="Prizes__text">Получайте карты-сюрпризы за покупки: </p>
                        <p className="Prizes__text">1 200₽ в «Гипер Ленте» и «Ленте Онлайн» 600₽ в «Супер Ленте», «Мини Ленте»</p>
                        <p className="Prizes__text"> А также за покупку товаров партнёров.</p>
                    </Div>
                </Group>
                <Group>
                    <Div>
                        <h3 className="Prizes__header">Вас ждут гарантированные призы:</h3>
                    </Div>
                </Group>
            </div>
            <TabbarLenta
                active={activePanel}
                go={go}
            ></TabbarLenta>
        </Panel>
    )
}

export default Prizes