import {
    SimpleCell,
} from '@vkontakte/vkui';
import { Icon16ErrorCircleFill, Icon28CheckCircleFill, Icon12Clock, Icon16InfoOutline } from '@vkontakte/icons';
import './Buy.css'
const Buy = ({ status, value, date, hidden }) => {



    if (status === 'error') {
        return (
            <SimpleCell
                description='Чек не засчитан'
                disabled={true}
                indicator={value}
                after={<Icon16InfoOutline width={19} height={19} color='#3F8AE0' />}
                before={
                    <Icon16ErrorCircleFill width={24} height={24} />
                }
                className={hidden ? 'Buy__err hidden' : 'Buy__err'}
            >
                {date}
            </SimpleCell>
        )
    }
    else if (status === 'process') {
        return (
            <SimpleCell
                description="Чек обрабатывается"
                disabled={true}
                indicator={value}
                before={
                    <Icon12Clock width={24} height={24} color='#3F8AE0' />
                }
                className={hidden ? 'hidden' : ''}
            >
                {date}
            </SimpleCell>
        )
    } else if (status === 'success') {
        return (
            <SimpleCell
                disabled={true}
                indicator={value}
                before={
                    <Icon28CheckCircleFill width={24} height={24} color='#3F8AE0' />
                }
                className={hidden ? 'hidden' : ''}
            >
                {date}
            </SimpleCell>
        )
    }
}

export default Buy