import './BindCard.css'
import PanelBannerTop from '../../components/PanelBannerTop/PanelBannerTop'
import BindBg from '../../components/PanelBannerTop/img/card-bg.jpg'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {
    Group,
    Div,
    FormLayout,
    FormItem,
    Input,
    Button,
    Panel,
    PanelHeader,
    PanelHeaderBack
} from "@vkontakte/vkui";




const BindCard = ({ go, activePanel }) => {
    return (
        <Panel >
            <PanelHeader
                transparent={true}
                separator={false}
                visor={false}
                className='PanelHeader__white-text'
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='main'
                        className='white-back'
                    />
                }
            >Карта №1</PanelHeader>
            <PanelBannerTop
                bg={BindBg}
            ></PanelBannerTop>
            <Group style={{ paddingBottom: '100px' }}>
                <FormLayout>
                    <FormItem
                        top="Фамилия и Имя"
                        status={true ? "valid" : "error"}
                        bottom={
                            true
                                ? "valid"
                                : "err"
                        }>
                        <Input
                            type="text"
                            name="name"
                        />
                    </FormItem>
                    <FormItem
                        top="Введите номер Карты №1"
                        status={false ? "valid" : "error"}
                    >
                        <Input
                            type="text"
                            name="card-number"
                        />
                    </FormItem>
                    <FormItem
                        top="Электронный адрес"
                        status='default'
                    >
                        <Input
                            type="email"
                            name="email"
                        />
                    </FormItem>
                    <div className="BindCard__buttons">
                        <FormItem style={{ paddingBottom: '0px' }}>
                            <Button
                                size="m"
                                stretched
                            >
                                Привязать карту
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button
                                size="m"
                                stretched
                                mode="secondary"
                                onClick={() => { location.href = 'https://lenta.com/karta1/' }}
                            >
                                Ещё нет карты?
                            </Button>
                        </FormItem>

                    </div>
                </FormLayout>
            </Group>
            <TabbarLenta
                active={activePanel}
                go={go}
            ></TabbarLenta>
        </Panel>
    )
}

export default BindCard