import MainBlock from "../components/MainBlock/MainBlock";
import React from "react";

export default function (item, params = {}, go, changeSelectedCatalogID, changeActualCatalogCard, openModal) {
    let promoButton = null;
    let sale = null;
    
    if (item.promo_button) {
        promoButton = JSON.parse(item.promo_button)
        promoButton.link = 'promo';
    }
    
    if (item.discount && item.color_button_discount) {
        sale = {
            text: item.discount,
            color: item.color_button_discount
        }
        
    }
    
    
    return (
        <MainBlock key={item.id}
                   title={item.name}
                   text={item.description}
                   buttonText={item.button}
                   rules={item.rules}
                   buttonLink={item.buttonLink || 'catalog'}
                   go={go}
                   changeSelectedCatalogID={changeSelectedCatalogID}
                   changeActualCatalogCard={changeActualCatalogCard}
                   id={item.id}
                   img={item.category_img}
                   date={item.date}
                   sale={sale}
                   openModal={openModal}
                   promoButton={promoButton}
                   {...params}
        />
    )
}