import './Cities.css'
import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Spacing,
    Text,
    Title,
    FormItem,
    Input,
    ScreenSpinner
} from "@vkontakte/vkui";
import {Icon16Search} from '@vkontakte/icons';

import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import React, {useEffect, useState} from 'react';


import SimpleSearch from "../../components/Search/Search";
import SwitchMarket from "../../components/SwitchMarket/SwitchMarket";


const Cities = (props) => {
    
    
    useEffect(() => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    console.log([position.coords.latitude, position.coords.longitude])
                    if (position) {
                        // props.setMapCoords([position.coords.latitude, position.coords.longitude])
                        
                        if (
                            position.coords.longitude > 190 ||
                            position.coords.longitude < 19 ||
                            position.coords.latitude < 40) {
                            props.setVpnIsActive(true)
                        }
                        
                    }
                }
            );
        }
        
        console.log('props.mapOpenFirstTime ', props.mapOpenFirstTime)
        
        if (props.mapOpenFirstTime) {
            props.openAuthPopup()
        }
    
       
        
        
    }, []);
    
    
    return (
        <Panel id="cities" style={{pointerEvents: event}}>
            <PanelHeader before={null} after={null}>Лента</PanelHeader>
            
            <div className={`Cities`} style={{paddingBottom: 70, paddingTop: 5, pointerEvents: event}}>
                <Div>
                    <Title level="2" style={{marginBottom: 10}}>
                        Найдите свою «Ленту»
                    </Title>
                    <Text style={{fontSize: '15px'}}>
                        Выберите свой город. Мы покажем каталоги товаров с актуальными скидками в «Гипер Ленте» рядом с вами.
                    </Text>
                </Div>
                <SwitchMarket marketType={props.marketType} changeMarketType={props.changeMarketType}/>
                <Div>
                    
                    <SimpleSearch
                        setActivePanel={props.setActivePanel}
                        phone={props.phone}
                        email={props.email}
                        vk_user_id={props.vk_user_id}
                        openModal={props.openModal} setMapCoords={props.setMapCoords}
                        go={props.go}
                        changeMapOpenFirstTime={props.changeMapOpenFirstTime}
                        changeSelectedCityKey={props.changeSelectedCityKey}
                        changeSelectedCityName={props.changeSelectedCityName}
                        userName={props.userName}
                        userSurname={props.userSurname}
                        searchParams={props.searchParams}
                        marketType={props.marketType}
                        type={'page'}/>
                    <Spacing size={8}/>
                </Div>
            
            </div>
           
        
        </Panel>
    )
}

export default Cities