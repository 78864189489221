import './Map.css'
import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Spacing,
    Text,
    Title,
    FormItem,
    Input,
    ScreenSpinner, IconButton
} from "@vkontakte/vkui";
import {Icon16Search, Icon28ChevronBack} from '@vkontakte/icons';

import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import React, {useEffect, useState} from 'react';
import map_script from "./map_script";

import markerImg from './img/marker.png';
import SimpleSearch from "../../components/Search/Search";
import SwitchMarket from "../../components/SwitchMarket/SwitchMarket";


const Map = (props) => {
    const [activeTabs, setActiveTabs] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0);
       
        
        function createMap() {
            map_script(markerImg, props.openModal, props.setVpnIsActive, props.mapCoords)
            const preloader = document.querySelector('.vkuiScreenSpinner ')
            if (preloader) {
                preloader.remove()
                setActiveTabs(true)
            }
        }
    
        createMap()
    
        if (props.vpnIsActive) {
            props.openModal('vpn')
        }
    
        
    }, []);
    
    
    const [vpnOpened, setVpnOpened] = useState(false);
    let vpnBtnContent = 'VPN'
    
    if (vpnOpened) {
        vpnBtnContent = <span>У вас включен VPN? Рекомендуем<br/> его отключить для стабильной<br/> работы приложения.</span>
    }
    
    
    
    
    return (
        <Panel id="map" style={{pointerEvents: event}}>
            <PanelHeader left={
                <IconButton>
                    <Icon28ChevronBack
                        onClick={props.go}
                        data-to='main'
                    />
                </IconButton>
    
            } before={null} after={null}>Лента</PanelHeader>
            <div id={'script-loader'}/>
            <div className={`Map ${props.mapOpenFirstTime && 'Map_withoutBar'}`} style={{paddingBottom: 70, paddingTop: 5, pointerEvents: event}}>
                <Div>
                    <Title level="2" style={{marginBottom: 10}}>
                        Найдите свою «Ленту»
                    </Title>
                    <Text style={{fontSize: '15px'}}>
                        Укажите свой город. Мы покажем, где находятся ближайшие магазины.
                    </Text>
                </Div>
                    <SwitchMarket marketType={props.marketType} changeMarketType={props.changeMarketType}/>
                <Div>
                    
                    <SimpleSearch
                        setActivePanel={props.setActivePanel}
                        phone={props.phone}
                        email={props.email}
                        vk_user_id={props.vk_user_id}
                        openModal={props.openModal} setMapCoords={props.setMapCoords}
                        go={props.go}
                        changeMapOpenFirstTime={props.changeMapOpenFirstTime}
                        changeSelectedCityKey={props.changeSelectedCityKey}
                        changeSelectedCityName={props.changeSelectedCityName}
                        userName={props.userName}
                        userSurname={props.userSurname}
                        searchParams={props.searchParams}
                        marketType={props.marketType}
                        type={'map'}/>
                    <Spacing size={8}/>
                    <div id={'mapBox'} className={'Map_box'}>
                        <ScreenSpinner state="loading" id={'map-spinner'}/>
                        {props.vpnIsActive
                        &&
                        <Button
                            size="s"
                            appearance={'negative'}
                            className={`Map_vpn ${vpnOpened ? 'Map_vpn_active' : null}`}
                            onClick={() => {setVpnOpened(!vpnOpened)}}
                            hasActive={false}
                        >
                            {vpnBtnContent}
                        </Button>
                        }
                        
                    </div>
                </Div>
            
            </div>
            {/*{!props.mapOpenFirstTime &&*/}
            {/*    <div style={{zIndex: 7}}>*/}
            {/*        <TabbarLenta*/}
            {/*            active={props.active}*/}
            {/*            go={props.go}*/}
            {/*        ></TabbarLenta>*/}
            {/*    </div>*/}
            {/*}*/}
            <div style={{zIndex: 7}} className={activeTabs ? '' : 'Map_tabs_disabled'}>
                <TabbarLenta
                    active={props.active}
                    go={props.go}
                />
            </div>
        </Panel>
    )
}

export default Map