import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
    Group,
    IconButton,
    SimpleCell,
    Header,
    Div,
    Button,
    Text
} from '@vkontakte/vkui';
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta';
import './History.css'
import Stage from '../../components/Stage/Stage'
import StageData from '../../data/stage.json'
import { useEffect, useState } from 'react';
import Buy from '../../components/Buy/Buy'
import historyData from '../../data/history.json'
const History = ({ go, active }) => {
    const [activeStage, setActiveStage] = useState(0)
    const [isHidden, setIsHidden] = useState(true)

    useEffect(() => {
        if (historyData['history'].length < 7) {
            setIsHidden(false);
        }
    }, []

    )

    return (

        <Panel >
            <PanelHeader
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='profile'
                    />
                }>Чеки</PanelHeader>
            <TabbarLenta
                active={active}
                go={go}
            ></TabbarLenta>
            <Group>
                <Header mode="secondary">Выберите этап розыгрыша</Header>
                <Div>
                    <ul className="History__stage-list">
                        <li className={activeStage == 0 ? 'History__stage-item active' : 'History__stage-item'} onClick={() => { setActiveStage(0) }}>1</li>
                        <li className={activeStage == 1 ? 'History__stage-item active' : 'History__stage-item'} onClick={() => { setActiveStage(1) }}>2</li>
                        <li className={activeStage == 2 ? 'History__stage-item active' : 'History__stage-item'} onClick={() => { setActiveStage(2) }}>3</li>
                        <li className={activeStage == 3 ? 'History__stage-item active' : 'History__stage-item'} onClick={() => { setActiveStage(3) }}>4</li>
                        <li className={activeStage == 4 ? 'History__stage-item active' : 'History__stage-item'} onClick={() => { setActiveStage(4) }}>5</li>
                    </ul>
                </Div>
                {StageData['stage'].map((item, i) => {
                    return (
                        <Stage
                            hidden={!(activeStage == i)}
                            sum={item.sum + ' ₽'}
                            stage={item.stageN}
                            key={i}

                        />
                    )
                })}

            </Group>
            <Group >
                <Div >
                    <Header mode="secondary">История покупок</Header>
                    {historyData['history'].map((item, i) => {
                        return (
                            <Buy
                                status={item.status}
                                value={item.value}
                                date={item.date}
                                key={i + item.date}
                                hidden={i >= 7 ? isHidden : false}
                            />
                        )
                    })}


                </Div>
                <Div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className={isHidden ? '' : 'hidden'}
                >
                    <Button
                        mode="tertiary"
                        align='center'
                        onClick={() => { setIsHidden(false) }}
                    >Показать все</Button>
                </Div>
            </Group>
            <Group style={{ paddingBottom: '150px' }}>
                <Div>
                    <Text weight="regular" style={{ color: '#818C99' }}>
                        Один чек – одно участие в розыгрыше. <br />Покупки появятся на сайте в течение 3-х рабочих дней после окончания текущего этапа.
                    </Text>
                </Div>
            </Group>

            <Div
                className='History__fixed'

            >
                <Button
                    onClick={go}
                    data-to='scan'
                    size='l'
                    stretched={true}
                >Добавить чек</Button>
            </Div>
        </Panel>

    )
}

export default History