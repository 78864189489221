import {
    Search,
    Cell,
    PanelHeaderButton,
    PanelHeader,
    Group,
    SimpleCell,
    Footer, Input, Button
} from '@vkontakte/vkui';
import './Search.css'
import React from "react";
import {Icon16Search, Icon16Chevron} from "@vkontakte/icons";
import {default as axios} from "axios";
import getCities from "../../functions/getCities";

const cities = getCities();


class SimpleSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            founded: true,
            canSend: true
        };
        this.onChange = this.onChange.bind(this);
    }
    
    componentDidMount() {
        if (this.props.type && this.props.type === 'page') {
            this.setState({
                founded: false
            })
        }
    }
    
    setCanSend = (status) => {
        this.setState({
            canSend: status
        })
    }
    
    onChange(e) {
        this.setState({search: e.target.value, founded: false});
    }
    
    get cities() {
        const search = this.state.search.toLowerCase();
        return cities.filter(
            ({name}) => name.toLowerCase().indexOf(search) > -1
        );
    }
    
    render() {
        const {search, founded, canSend} = this.state;
        
        const inputStyle = {
            position: "relative",
            
         
            margin: 0,
            height: "36px",
            fontSize: 17,
            lineHeight: "20px",
            textOverflow: "ellipsis",
            color: "#818C99",
            border: "none",
            background: "transparent",
            zIndex: 2,
        };
        
        
        return (
            <React.Fragment>
                
                <Group>
                    
                    <div className={`Search_wrapper`}>
                        <Input
                            className={'Search_input'}
                            value={this.state.search}
                            onClick={() => {
                                this.setState({
                                    founded: false
                                })
                            }}
                            onChange={this.onChange}
                            before={
                                <Icon16Search style={{color: '#818C99'}}/>
                            }
                            after={null}
                            style={inputStyle}
                            type="text"
                            align={'left'}
                            placeholder="Поиск"
                        />
    
                        {
                            !founded
                            
                            &&

                            <Button
                                className={'Search_button'}
                                size='m'
                                mode={'tertiary'}
                                appearance={'accent'}
                                onClick={() => {
                                    this.setState({
                                        search: '',
                                        founded: true
                                    })
                                }}
                            >Отменить</Button>
                        }
                       
                        
                    </div>
                   
                    
                    {!founded && this.cities.length > 0 &&
                    <div className={`Search_list Search__${this.props.type}`}>
                        {
                            this.cities.map((city, index) => {
                                const coords = city.coords.split(",");
                                
                                return (
                                    <Cell key={index} data-to='main' onClick={(e) => {
                                        if (this.props.type && this.props.type === 'map') {
                                            window.myMap && window.myMap.setCenter(coords, 10);
                                            // this.props.openModal('city', {
                                            //     cityName: city.name,
                                            //     redirectToCatalog: true,
                                            //     cityKey: city.key,
                                            // })
                                        }
    
                                        this.props.changeSelectedCityName(city.name)
                                        this.props.changeSelectedCityKey(city.key)
                                        this.props.changeMapOpenFirstTime(false)
    
                                        this.props.setMapCoords(city.coords.split(","))
    
    
                                        let url = window.location.origin;
                                        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0|| url.indexOf('vk-apps') >= 0) {
                                            url = 'https://devvkminiapp.lenta.com'
                                        }
    
    
                                        if (canSend) {
                                            this.setCanSend(false)
                                            const _this = this;
        
                                            let info = {
                                                vk_id: this.props.vk_user_id,
                                                city_key: city.key,
                                                name: _this.props.userName,
                                                surname: _this.props.userSurname,
                                                market_type: _this.props.marketType
                                            }
        
                                            if (_this.props.type && _this.props.type === 'page') {
                                                info.phone = _this.props.phone;
                                                info.email = _this.props.email;
                                            }
    
    
                                            const clearSearch = _this.props.searchParams.substring(1);
                                            const convertedSearch = JSON.parse('{"' + clearSearch.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
                                            info.user = _this.props.searchParams;
                                            
                                            axios.post(url + `/api/user`, info)
                                                .then(function (response) {
                                                    // обработка успешного запроса
                                                    _this.setCanSend(true)
                
                                                    const data = response.data;
                
                                                    if (data.status && _this.props.type === 'page') {
                                                        _this.props.setActivePanel('main')
                                                    }
                
                                                    else {
                    
                                                    }
                
                
                                                })
                                                .catch(function (error) {
                                                    // обработка ошибки
                                                    console.log(error);
                                                    _this.setCanSend(true)
                
                                                })
                                        }
                                       
                                        this.setState({
                                            search: '',
                                            founded: true
                                        })
                                        
                                        
                                        
                                    }}
                                          after={<Icon16Chevron style={{color: '#B8C1CC'}}/>}
                                    >{city.name}</Cell>
                                )
                            })
                        }
                    </div>
                    }
                    {this.cities.length === 0 && <Footer>Ничего не найдено</Footer>}
                </Group>
            </React.Fragment>
        );
    }
}


export default SimpleSearch
