const time = {
    'hours': [
        {
            label: "00",
            description: "",
            value: "00",
        },
        {
            label: "01",
            description: "",
            value: "01",
        },
        {
            label: "02",
            description: "",
            value: "02",
        },
        {
            label: "03",
            description: "",
            value: "03",
        },
        {
            label: "04",
            description: "",
            value: "04",
        },
        {
            label: "05",
            description: "",
            value: "05",
        },
        {
            label: "06",
            description: "",
            value: "06",
        },
        {
            label: "07",
            description: "",
            value: "07",
        },
        {
            label: "08",
            description: "",
            value: "08",
        },
        {
            label: "09",
            description: "",
            value: "09",
        },
        {
            label: "10",
            description: "",
            value: "10",
        },
        {
            label: "11",
            description: "",
            value: "11",
        },
        {
            label: "12",
            description: "",
            value: "12",
        },
        {
            label: "13",
            description: "",
            value: "13",
        },
        {
            label: "14",
            description: "",
            value: "14",
        },
        {
            label: "15",
            description: "",
            value: "15",
        },
        {
            label: "16",
            description: "",
            value: "16",
        },
        {
            label: "17",
            description: "",
            value: "17",
        },
        {
            label: "18",
            description: "",
            value: "18",
        },
        {
            label: "19",
            description: "",
            value: "19",
        },
        {
            label: "20",
            description: "",
            value: "20",
        },
        {
            label: "21",
            description: "",
            value: "21",
        },
        {
            label: "22",
            description: "",
            value: "22",
        },
        {
            label: "23",
            description: "",
            value: "23",
        }

    ],
    "minutes": [
        {
            label: "00",
            description: "",
            value: "00",
        },
        {
            label: "01",
            description: "",
            value: "01",
        },
        {
            label: "02",
            description: "",
            value: "02",
        },
        {
            label: "03",
            description: "",
            value: "03",
        },
        {
            label: "04",
            description: "",
            value: "04",
        },
        {
            label: "05",
            description: "",
            value: "05",
        },
        {
            label: "06",
            description: "",
            value: "06",
        },
        {
            label: "07",
            description: "",
            value: "07",
        },
        {
            label: "08",
            description: "",
            value: "08",
        },
        {
            label: "09",
            description: "",
            value: "09",
        },
        {
            label: "10",
            description: "",
            value: "10",
        },
        {
            label: "11",
            description: "",
            value: "11",
        },
        {
            label: "12",
            description: "",
            value: "12",
        },
        {
            label: "13",
            description: "",
            value: "13",
        },
        {
            label: "14",
            description: "",
            value: "14",
        },
        {
            label: "15",
            description: "",
            value: "15",
        },
        {
            label: "16",
            description: "",
            value: "16",
        },
        {
            label: "17",
            description: "",
            value: "17",
        },
        {
            label: "18",
            description: "",
            value: "18",
        },
        {
            label: "19",
            description: "",
            value: "19",
        },
        {
            label: "20",
            description: "",
            value: "20",
        },
        {
            label: "21",
            description: "",
            value: "21",
        },
        {
            label: "22",
            description: "",
            value: "22",
        },
        {
            label: "23",
            description: "",
            value: "23",
        },
        {
            label: "24",
            description: "",
            value: "24",
        },
        {
            label: "25",
            description: "",
            value: "25",
        },
        {
            label: "26",
            description: "",
            value: "26",
        },
        {
            label: "27",
            description: "",
            value: "27",
        },
        {
            label: "28",
            description: "",
            value: "28",
        },
        {
            label: "29",
            description: "",
            value: "29",
        },
        {
            label: "30",
            description: "",
            value: "30",
        },
        {
            label: "31",
            description: "",
            value: "31",
        },
        {
            label: "32",
            description: "",
            value: "32",
        },
        {
            label: "33",
            description: "",
            value: "33",
        },
        {
            label: "34",
            description: "",
            value: "34",
        },
        {
            label: "35",
            description: "",
            value: "35",
        },
        {
            label: "36",
            description: "",
            value: "36",
        },
        {
            label: "37",
            description: "",
            value: "37",
        },
        {
            label: "38",
            description: "",
            value: "38",
        },
        {
            label: "39",
            description: "",
            value: "39",
        },
        {
            label: "40",
            description: "",
            value: "40",
        },
        {
            label: "41",
            description: "",
            value: "41",
        },
        {
            label: "42",
            description: "",
            value: "42",
        },
        {
            label: "43",
            description: "",
            value: "43",
        },
        {
            label: "44",
            description: "",
            value: "44",
        },
        {
            label: "45",
            description: "",
            value: "45",
        },
        {
            label: "46",
            description: "",
            value: "46",
        },
        {
            label: "47",
            description: "",
            value: "47",
        },
        {
            label: "48",
            description: "",
            value: "48",
        },

        {
            label: "49",
            description: "",
            value: "49",
        },
        {
            label: "50",
            description: "",
            value: "50",
        },
        {
            label: "51",
            description: "",
            value: "51",
        },
        {
            label: "52",
            description: "",
            value: "52",
        },
        {
            label: "53",
            description: "",
            value: "53",
        },
        {
            label: "54",
            description: "",
            value: "54",
        },
        {
            label: "55",
            description: "",
            value: "55",
        },
        {
            label: "56",
            description: "",
            value: "56",
        },
        {
            label: "57",
            description: "",
            value: "57",
        },
        {
            label: "58",
            description: "",
            value: "58",
        },
        {
            label: "59",
            description: "",
            value: "59",
        },
    ]
}

export default time