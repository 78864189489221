import {
    Div,
    Button,
    Card,
    ContentCard,
    Link,
    Spacing
} from "@vkontakte/vkui";
import './MainBlock.css';
import {
    Icon24ChevronCompactRight,
    
} from '@vkontakte/icons';


const MainBlock = ({
                       title,
                       text,
                       buttonText,
                       buttonLink,
                       isCatalog,
                       go,
                       rules,
                       img,
                       date,
                       promoButton,
                       sale,
                       rulesLink,
                       openModal,
                       id,
                       changeSelectedCatalogID,
                       changeActualCatalogCard,
                       setEventData = null,
                       eventData = null
                   }) => {
    let buttonStyles = {};
    if (sale && sale.color) {
        switch (sale.color) {
            case 'white':
                buttonStyles = {
                    backgroundColor: 'white'
                }
                break;
            
            case 'red':
                buttonStyles = {
                    color: '#FFFFFF',
                    backgroundColor: '#FF3347'
                }
                break;
            
            case 'green':
                buttonStyles = {
                    color: '#FFFFFF',
                    backgroundColor: '#2A3C32'
                }
                break;
        }
    }
    
    
    let caption = null;
    
    if (buttonText) {
        caption = <Div style={{paddingLeft: 0, paddingBottom: 6, paddingTop: 8}}>
            <Button
                size='l'
                onClick={(e) => {
                    
                    console.log('setEventData 2', setEventData)
                    console.log('eventData 2', eventData)
                    if (setEventData) {
                        setEventData(eventData)
    
                        go(e)
                    } else {
                        changeActualCatalogCard({
                            title,
                            text,
                            go,
                            promoButton,
                            img,
                            date,
                            sale,
                            rules,
                            rulesLink,
                            openModal,
                            id
                        })
                        changeSelectedCatalogID(id)
                        go(e)
                    }
                }}
                data-to={buttonLink}
                appearance={'accent'}
                
                after={<Icon24ChevronCompactRight aria-hidden/>}
            >{buttonText}</Button>
        </Div>
    }
    
    if (promoButton) {
        caption = <Div style={{paddingLeft: 0, paddingBottom: 6, paddingTop: 8}}>
            
            <Button
                size='l'
                onClick={(e) => {
                    changeActualCatalogCard({
                        title,
                        text,
                        go,
                        img,
                        date,
                        promoButton,
                        sale,
                        rules,
                        rulesLink,
                        openModal,
                        id
                    })
                    go(e)
                    changeSelectedCatalogID(id)
                }}
                data-to={promoButton.link}
                appearance={'accent'}
                style={{
                    color: '#FFFFFF',
                    backgroundColor: promoButton.color
                }}
                after={<Icon24ChevronCompactRight aria-hidden/>}
            >{isCatalog ? promoButton.textCatalog : promoButton.text}</Button>
            {!isCatalog &&
            <div>
                <Spacing size={8}/>
                <Button
                    size='l'
                    onClick={(e) => {
                        console.log('setEventData ', setEventData)
                        
                        if (setEventData) {
                            setEventData(eventData)
                        } else {
                            changeActualCatalogCard({
                                title,
                                text,
                                go,
                                img,
                                date,
                                sale,
                                promoButton,
                                rules,
                                rulesLink,
                                openModal,
                                id
                            })
                            changeSelectedCatalogID(id)
                            go(e)
                        }
                        
                    }}
                    data-to={buttonLink}
                    appearance={'accent'}
                    
                    after={<Icon24ChevronCompactRight aria-hidden/>}
                >{buttonText}</Button>
            </div>
            }
        </Div>
    } else if (rulesLink) {
        caption = <Div style={{paddingLeft: 0, paddingBottom: 8, paddingTop: 12}}>
            <Link onClick={() => {
                console.log('rulesrules', rules);
                openModal('agreement', {rules})
            }}
                  style={{
                      border: '1px solid #3F8AE0',
                      borderRadius: 10,
                      paddingTop: 2,
                      paddingBottom: 4,
                      paddingLeft: 16,
                      paddingRight: 16,
                      fontSize: 14,
                  }}
            >Уведомление об условиях</Link>
        </Div>
    }
    
    
    return (
        <div style={{position: 'relative', paddingBottom: 8, width: '100%'}} className={'MainBlock'}>
            {sale &&
            
            <div style={{position: 'absolute', top: 16, right: 16, zIndex: 5}}>
                <Button
                    size='s'
                    hasActive={false}
                    data-to={buttonLink}
                    appearance={'neutral'}
                    style={buttonStyles}
                >{sale.text}</Button>
            </div>
            }
            
            
            <ContentCard
                src={img}
                subtitle={date}
                header={title}
                text={text}
                caption={caption}
            />
        </div>
    )
}

export default MainBlock