import './Notifications.css'
import PanelBannerTop from '../../components/PanelBannerTop/PanelBannerTop'
import prizesBg from '../../components/PanelBannerTop/img/prize-bg.jpg'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {useEffect, useState} from 'react'

import {
    Group,
    Div,
    Panel,
    PanelHeader,
    FormItem,
    Textarea,
    Input,
    PanelHeaderBack, IconButton, Button, Spacing
    
} from "@vkontakte/vkui";
import {Icon28ChevronBack} from "@vkontakte/icons";
import bridge from "@vkontakte/vk-bridge";
import {default as axios} from "axios";
import getCities from "../../functions/getCities";
import queryString from "query-string";

const Notifications = ({activePanel, go, userID, pushText, searchParams}) => {
    
    const [pageIsOpened, changePageIsOpened] = useState(false);
    const [name, changeName] = useState('');
    const [text, changeText] = useState(pushText);
    const [paramsText, changeParamsText] = useState('');
    const [userIds, changeUserIds] = useState(userID);
    const [canSend, changeCanSend] = useState(true);
    
    const [login, changeLogin] = useState('');
    const [password, changePassword] = useState('');
    let url = window.location.origin;
    let token = 'ab8f3ee0ab8f3ee0ab8f3ee0c5a89f52d3aab8fab8f3ee0c88c9dddfe8114fa73ba65b9'
    if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0 || url.indexOf('vk-apps') >= 0) {
        url = 'https://devvkminiapp.lenta.com'
        token = '76f1601d76f1601d76f1601d2e768c24ee776f176f1601d1430a1d9e2b26148468ee85d'
    }
    
    const clearSearch = searchParams.substring(1);
    const convertedSearch = JSON.parse('{"' + clearSearch.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    
    
    let content = null;
    
    if (pageIsOpened) {
        content = <Group>
            <FormItem top="Название рассылки">
                <Textarea placeholder="Пожалуйста, введите название рассылки"
                          minLength={5}
                          maxLength={124}
                          value={name}
                          onChange={e => changeName(e.target.value)}
                />
            </FormItem>
            <FormItem top="Текст сообщения"
                      bottom="Максимальная длина текстового сообщения — 254 символа. Рассылка уведомлений не более 1-го раза в сутки.">
        
                <Textarea placeholder="Пожалуйста, введите текст сообщения"
                          maxLength={254}
                          value={text}
                          onChange={e => changeText(e.target.value)}
                />
            </FormItem>
            {/*<FormItem top="Параметры для обратной ссылки (если требуются)">*/}
            {/*    <Textarea placeholder="city=spb"*/}
            {/*              value={paramsText}*/}
            {/*              onChange={e => changeParamsText(e.target.value)}*/}
            {/*    />*/}
            {/*</FormItem>*/}
            <FormItem top="Список id пользователей (через запятую)">
                <Textarea placeholder="222333441, 222333442, 222333443"
                          value={userIds}
                          onChange={e => changeUserIds(e.target.value)}
                />
                <Spacing size={12}/>
                <Button
                    size='m'
                    appearance={'negative'}
                    onClick={() => {
                        let url = window.location.origin;
                        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0 || url.indexOf('vk-apps') >= 0) {
                            url = 'https://devvkminiapp.lenta.com'
                        }
                
                
                        if (canSend) {
                            changeCanSend(false);
                            axios.get(url + `/api/users` + searchParams)
                                .then(function (response) {
                                    // обработка успешного запроса
                            
                                    const data = response.data;
                            
                                    if (data && data.users) {
                                        changeUserIds(data.users)
                                
                                    }
                            
                            
                                    changeCanSend(true);
                            
                                    console.log(response);
                                })
                                .catch(function (error) {
                                    // обработка ошибки
                                    console.log(error);
                            
                                    changeCanSend(true);
                                })
                    
                        }
                
                    }}
                >Получить список ID с сервера</Button>
            </FormItem>
            <FormItem>
                <Button
                    size='l'
            
                    appearance={'accent'}
                    onClick={() => {
                        if (name.length >= 5) {
                            let counter = 0;
                            console.log('userIds ', userIds)
                            let usersArray = userIds;
                            if (Array.isArray(userIds)) {
                                usersArray = [...userIds];
                            }
                    
                    
                            let array = usersArray; //массив, можно использовать массив объектов
                            let size = 100; //размер подмассива
                            let subarray = []; //массив в который будет выведен результат.
                            for (let i = 0; i <Math.ceil(array.length/size); i++){
                                subarray[i] = array.slice((i*size), (i*size) + size);
                            }
                            const users = subarray;
                    
                            users.forEach(users100 => {
                                bridge.send("VKWebAppCallAPIMethod", {
                                    method: "notifications.sendMessage",
                                    params: {
                                        user_ids: users100.toString(),
                                        message: text,
                                        fragment: paramsText,
                                        v: "5.131",
                                        access_token: token
                                    }
                                }).then((data) => {
                                    const { response } = data;
                                    console.log('response ', response)
                                    response.forEach((item) => {
                                        console.log('item ', item)
                                        if (item.status) {
                                            counter++;
                                        }
                                    })
                            
                                    console.log(data);
                            
                                })
                                    .catch((error) => {
                                        // Ошибка
                                        console.log(error);
                                    });
                            })
                    
                    
                    
                          
                    
                            setTimeout(() => {
                                let info = {
                                    log_count: counter,
                                    user: convertedSearch,
                                    name: name
                                }
                        
                                axios.post(url + `/api/users`, info)
                                    .then(function (response) {
                                        console.log(response);
                                        changeName('')
                                        changeText('')
                                
                                    })
                                    .catch(function (error) {
                                        // обработка ошибки
                                        console.log(error);
                                    })
                            }, 1000)
                        }
                
                    }}
                >Отправить</Button>
            </FormItem>
    
    
            <Spacing size={124}/>

        </Group>
    }
    
    else {
        content = <Group>
            <FormItem top="Логин">
                <Input type={'text'} placeholder="login"
                          value={login}
                          onChange={e => changeLogin(e.target.value)}
                />
            </FormItem>
    
            <FormItem top="Пароль">
                <Input type={'password'} placeholder="password"
                       value={password}
                       onChange={e => changePassword(e.target.value)}
                />
            </FormItem>
    
            <FormItem>
                <Button
                    size='l'
                    appearance={'accent'}
                    onClick={() => {
                        axios.post(url + `/api/login`, {
                            login,
                            password,
                            user: convertedSearch,
                        })
                            .then(function (response) {
                                console.log(response);
                                if (response.status) {
                                    changePageIsOpened(true)
                                }
            
                            })
                            .catch(function (error) {
                                // обработка ошибки
                                console.log(error);
                            })
                    }}
                >
                    Авторизоваться
                </Button>
            </FormItem>
        </Group>
    }
    
    return (
        
        <Panel>
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={go}
                            data-to='main'
                        />
                    </IconButton>
                    
                }>Уведомления</PanelHeader>
            {content}
            
            
            <TabbarLenta
                active={activePanel}
                go={go}
            />
        </Panel>
    )
}

export default Notifications