import {Tabs, TabsItem} from "@vkontakte/vkui";

const SwitchMarket = ({ marketType, changeMarketType, disabledMini= false }) => {
    return (
        <Tabs mode={"default"}>
            <TabsItem
                selected={marketType === 'hypermarket'}
                onClick={() => changeMarketType("hypermarket")}
            >
                Гипер Лента
            </TabsItem>
            <TabsItem
                selected={marketType === 'supermarket'}
                onClick={() => changeMarketType("supermarket")}
            >
                Супер Лента
            </TabsItem>
            {!disabledMini &&
                <TabsItem
                    selected={marketType === 'minimarket'}
                    onClick={() => changeMarketType("minimarket")}
                >
                    Мини Лента
                </TabsItem>
            }
            
        </Tabs>
    )
}

export default SwitchMarket
