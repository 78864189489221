import './Coupon.css'
import React from "react";
import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Spacing,
    Select,
    CustomSelect,
    CustomSelectOption,
    Text,
    Title,
    Separator,
    HorizontalScroll,
    Counter,
    PanelHeaderButton,
    ModalRoot,
    ModalPage,
    ModalPageHeader, ScreenSpinner, IconButton
} from "@vkontakte/vkui";
import {Icon24Cancel, Icon28ChevronBack} from '@vkontakte/icons';
import {Icon24ChevronDown} from '@vkontakte/icons';
import {Icon24ChevronUp} from '@vkontakte/icons';
import MainBlock from '../../components/MainBlock/MainBlock'

import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {useEffect, useState} from 'react';
import Card from "../../components/Card/Card";
import {default as axios} from "axios";
import createCard from "../../functions/createCard";
import SwitchMarket from "../../components/SwitchMarket/SwitchMarket";
import BlockEmpty from "../../components/BlockEmpty/BlockEmpty";

const Coupon = ({go, active, openModal, selectedCityKey, selectedCategoryID, marketType, changeMarketType, selectedCatalogID, actualCatalogCard, changeSelectedCatalogID, changeActualCatalogCard}) => {
    const [data, setData] = useState(null);
 
  
  
    
    useEffect(() => {
      
        let address = 'coupons';
        
        
        let url = window.location.origin;
        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0|| url.indexOf('vk-apps') >= 0) {
            url = 'https://devvkminiapp.lenta.com'
        }
        
        axios.get(url + `/api/${address}?city_key=spb`)
            .then(function (response) {
                // обработка успешного запроса
                
                const data = response.data;
                
                if (data) {
                    setData(data)
                }
                
                else {
                    setData({
                        error: 'Empty'
                    })
                }
                
               
    
                
                
                
                console.log(response);
            })
            .catch(function (error) {
                // обработка ошибки
                console.log(error);
                setData({
                    error: error.message
                })
                
            })
        
    }, [])
    
    
    let content = <Div style={{position: 'relative', zIndex: 10}}>
        <Spacing size={50}/>
        <ScreenSpinner state="loading" id={'catalog-spinner'}/>
    </Div>
    
    if (data && data.error) {
        content = <BlockEmpty
            changeActualCatalogCard={changeActualCatalogCard}
            changeSelectedCatalogID={changeSelectedCatalogID}
            go={go}
            type={'coupon'}
        />
    } else if (data) {
        
        
        const categories = data.category;
        console.log('categories', categories)

        // let selectedItems = data.filter(item => item.market_type === marketType || item.market_type === "both");
        let selectedItems = data.filter(item => item.market_type === 'hypermarket' || item.market_type === "both");
       
        
        console.log('selectedItems', selectedItems)
        
        let itemsElems = [];
        
        if (Array.isArray(selectedItems) && selectedItems.length) {
            
            itemsElems = selectedItems.map((item, index) => {
                return (
                    <li className={'Catalog_card'} key={item.title + item.anons_img + index} onClick={() => {
                        openModal('coupon_info', {...item, categoryName: null})
                    }}>
                        <Card
                            type={'small'}
                            imageLarge={true}
                            image={item.anons_img}
                            title={`Купон на скидку ${item.discount}`}
                            sale={item.discount}
                            text={item.discount_date}
                            url={item.url}
                        />
                    </li>
                )
            })
        }
        
        else {
            itemsElems = <BlockEmpty
                changeActualCatalogCard={changeActualCatalogCard}
                changeSelectedCatalogID={changeSelectedCatalogID}
                go={go}
                type={'coupon'}
            />
        }
    
    
    
    
    
    
    
        

        content = <React.Fragment>
            
            <Div>
               
                <ul className={'Catalog_cards'}>
                    {itemsElems}
                </ul>
            </Div>
        </React.Fragment>
        
    }
    

    
    
    
    return (
        
        <Panel id="catalog">
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={go}
                            data-to='main'
                        />
                    </IconButton>
                }
            >Лента</PanelHeader>
            <div className={'Catalog'} style={{paddingBottom: 70, paddingTop: 10}}>
                <Div>
                    <Title level="2" style={{marginBottom: 10}}>
                        Секретные купоны
                    </Title>
                    <Text style={{fontSize: '15px'}}>
                        Покажите купон кассиру или просканируйте на кассе самообслуживания перед оплатой покупки.
                    </Text>
                </Div>
                {/*<SwitchMarket marketType={marketType} changeMarketType={changeMarketType}/>*/}
                
                {content}
            </div>
            <div style={{zIndex: 7}}>
                <TabbarLenta
                    active={active}
                    go={go}
                />
            </div>
        </Panel>
    )
}

export default Coupon