
import {
    SimpleCell,
    Header,
    Div,

} from '@vkontakte/vkui';

const Stage = ({ hidden, sum, stage }) => {
    return (
        <Div hidden={hidden}>
            <Header mode="secondary">Сумма покупок</Header>
            <SimpleCell
                indicator={sum}
                style={{ fontWeight: 'bold' }}
            >
                <b>За {stage} этап</b>
            </SimpleCell>
            <SimpleCell
                indicator="12 578,50 ₽"
            >
                <b>За все этапы</b>
            </SimpleCell>
        </Div>
    )
}

export default Stage