import './Profile.css'
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {
    Avatar,
    Div,
    Group,
    SimpleCell,
    Card,
    Button,
    Panel,
    PanelHeader,
} from "@vkontakte/vkui";
import ex from './img/ex2.jpg'
import {
    Icon24PaymentCardOutline,
    Icon20CheckCircleOn,
    Icon16Chevron,
    Icon24DocumentOutline,
    Icon24HelpOutline,
    Icon28TouchIdOutline,
    Icon24Place
} from '@vkontakte/icons';

import bill from './img/bill.svg'

const Profile = ({ go, fName, lName, city, photo, activePanel }) => {

    // удалить на релизе
    if (!fName) {
        fName = 'Константин Константинопольский'
        city = 'Санкт-Петербург'
        photo = ex
    }

    return (
        <Panel >
            <PanelHeader>Мой профиль</PanelHeader>
            <Div>
                <SimpleCell
                    description={city}
                    before={<Avatar src={photo} size={72}
                    />}
                >
                    {fName + ' ' + lName}
                </SimpleCell>
            </Div>
            <Div>
                <Card
                    mode="shadow">
                    <SimpleCell
                        before={<Icon20CheckCircleOn />}
                        disabled={true}
                        after={<Button
                            mode='tertiary'
                        >Отвязать</Button>}
                    >
                        Карта №1
                    </SimpleCell>
                    <Div style={{ paddingTop: '0px' }}>
                        <p className="Profile__card-caption">
                            Ваша карта №1 успешно зарегестрирована. Покупайте товары для участия в акции<br />
                        </p>
                        <p className="Profile__card-caption">Ваш номер «Карты №1»: 123 456 789 01</p>
                    </Div>
                </Card>
            </Div>

            <Div style={{ paddingBottom: '100px' }}>
                <Group>
                    <SimpleCell
                        before={<img className='Profile__icon' src={bill} />}
                        after={<Button
                            mode='tertiary'
                            before={<Icon16Chevron style={{ color: '#B8C1CC' }} />}
                        ></Button>}
                        onClick={go}
                        data-to='history'
                    >
                        Чеки
                    </SimpleCell>
                    <SimpleCell
                        before={<Icon24DocumentOutline />}
                        after={<Button
                            mode='tertiary'
                            before={<Icon16Chevron style={{ color: '#B8C1CC' }} />}
                        ></Button>}
                        onClick={go}
                        data-to='rules'
                    >
                        Полные правила акции
                    </SimpleCell>
                    <SimpleCell
                        before={<Icon24Place />}
                        after={<Button
                            mode='tertiary'
                            before={<Icon16Chevron style={{ color: '#B8C1CC' }} />}
                        ></Button>}
                        onClick={() => { location.href = 'https://lenta.com/allmarkets/'; }}
                        data-to='map'
                    >
                        Найди свою Ленту
                    </SimpleCell>

                </Group>
                <Group>
                    <SimpleCell
                        before={<Icon24HelpOutline />}
                        after={<Button
                            mode='tertiary'
                            before={<Icon16Chevron style={{ color: '#B8C1CC' }} />}
                        ></Button>}
                        onClick={go}
                        data-to='help'
                    >
                        Помощь
                    </SimpleCell>
                    <SimpleCell
                        before={<Icon28TouchIdOutline width={24} height={24} />}
                        after={<Button
                            mode='tertiary'
                            before={<Icon16Chevron style={{ color: '#B8C1CC' }} />}
                        ></Button>}
                        onClick={go}
                        data-to='personal-data'
                    >
                        Персональные данные
                    </SimpleCell>
                </Group>
            </Div>

            <TabbarLenta
                active={activePanel}
                go={go}
            ></TabbarLenta>
        </Panel>
    )
}

export default Profile
