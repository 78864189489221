export default function getCities() {
    return [
        {name: "Санкт-Петербург и ЛО", key: "spb", coords: "59.938955,30.315644"},
        {name: "Москва и МО", key: "msk", coords: "55.755864,37.617698"},
        {name: "Альметьевск", key: "alm", coords: "54.901171,52.2972"},
        {name: "Армавир", key: "arm", coords: "44.997655,41.129644"},
        {name: "Архангельск", key: "arh", coords: "64.539911,40.515762"},
        {name: "Астрахань", key: "ast", coords: "46.347614,48.030178"},
        {name: "Ачинск", key: "achinsk", coords: "56.269501,90.49524"},
        {name: "Балаково", key: "blk", coords: "52.01843,47.819676"},
        {name: "Балашиха", key: "msk", coords: "55.797007,37.938118"},
        {name: "Барнаул", key: "brn", coords: "53.346785,83.776856"},
        {name: "Белгород", key: "blg", coords: "50.595414,36.587277"},
        {name: "Бийск", key: "bsk", coords: "52.541811,85.220656"},
        {name: "Братск", key: "bratsk", coords: "56.151682,101.633505"},
        {name: "Брянск", key: "brnsk", coords: "53.243562,34.363425"},
        {name: "Великий Новгород", key: "vnov", coords: "58.522857,31.269816"},
        {name: "Владимир", key: "vld", coords: "56.129057,40.406635"},
        {name: "Волгоград", key: "vlg", coords: "48.707067,44.516975"},
        {name: "Волжский", key: "vlz", coords: "48.786127,44.751229"},
        {name: "Вологда", key: "vol", coords: "59.220501,39.891523"},
        {name: "Воронеж", key: "vrn", coords: "51.660781,39.200296"},
        {name: "Выборг", key: "spb", coords: "60.710496,28.749781"},
        {name: "Грозный", key: "grz", coords: "43.318366,45.692421"},
        {name: "Димитровград", key: "dmt", coords: "54.217515,49.623924"},
        {name: "Елино", key: "msk", coords: "54.412092,39.381612"},
        {name: "Екатеринбург", key: "ekat", coords: "56.838011,60.597474"},
        {name: "Железноводск", key: "jzl", coords: "44.131967,43.030506"},
        {name: "Иваново", key: "ivn", coords: "57.000353,40.97393"},
        {name: "Ижевск", key: "izv", coords: "56.852676,53.2069"},
        {name: "Иркутск", key: "irk", coords: "56.852676,53.2069"},
        {name: "Истра", key: "msk", coords: "55.91466,36.859017"},
        {name: "Казань", key: "kazan", coords: "55.796127,49.106414"},
        {name: "Калуга", key: "klg", coords: "54.513845,36.261224"},
        {name: "Каменск-Уральский", key: "kur", coords: "56.41524,61.917764"},
        {name: "Кемерово", key: "kmrv", coords: "55.355198,86.086847"},
        {name: "Кострома", key: "kostroma", coords: "57.767966,40.926858"},
        {name: "Краснодар", key: "krasnodar", coords: "45.03547,38.975313"},
        {name: "Красноярск", key: "krsn", coords: "56.010569,92.852572"},
        {name: "Курган", key: "krg", coords: "55.441004,65.341118"},
        {name: "Курск", key: "kursk", coords: "51.730846,36.193015"},
        {name: "Луховицы", key: "msk", coords: "54.965217,39.025403"},
        {name: "Липецк", key: "lpc", coords: "52.608826,39.599229"},
        {name: "Коломна", key: "msk", coords: "55.103034,38.752809"},
        {name: "Магнитогорск", key: "mgn", coords: "53.407163,58.980291"},
        {name: "Майкоп", key: "mkp", coords: "44.606683,40.105852"},
        {name: "Медведево (Йошкар-Ола)", key: "mdv", coords: "56.633293,47.803309"},
        {name: "Мурманск", key: "murm", coords: "68.970663,33.074918"},
        {name: "Набережные Челны", key: "nchel", coords: "55.741272,52.403662"},
        {name: "Наро-Фоминск", key: "msk", coords: "55.383838,36.72285"},
        {name: "Нижневартовск", key: "nvt", coords: "60.938545,76.558902"},
        {name: "Нижнекамск", key: "nkmsk", coords: "55.637576,51.819712"},
        {name: "Нижний Новгород", key: "nnvgrd", coords: "56.326797,44.006516"},
        {name: "Нижний Тагил", key: "ntgl", coords: "57.907562,59.971474"},
        {name: "Новокузнецк", key: "nkuz", coords: "53.757553,87.136053"},
        {name: "Новороссийск", key: "novoros", coords: "44.723771,37.768813"},
        {name: "Новосибирск", key: "nsk", coords: "55.030204,82.92043"},
        {name: "Новочеркасск", key: "nvch", coords: "47.422052,40.093734"},
        {name: "Новошахтинск", key: "nshakh", coords: "47.754315,39.934705"},
        {name: "Ноябрьск", key: "noy", coords: "63.201805,75.450938"},
        {name: "Обнинск", key: "obn", coords: "55.100412,36.609654"},
        {name: "Омск", key: "omsk", coords: "54.989347,73.368221"},
        {name: "Орёл", key: "orl", coords: "52.970756,36.064358"},
        {name: "Оренбург", key: "orn", coords: "51.768205,55.096964"},
        {name: "Орск", key: "orsk", coords: "51.2293,58.474675"},
        {name: "Пенза", key: "penza", coords: "53.195042,45.018316"},
        {name: "Пермь", key: "prm", coords: "58.010455,56.229443"},
        {name: "Петрозаводск", key: "karelia", coords: "61.785021,34.346878"},
        {name: "Прокопьевск", key: "prk", coords: "53.884493,86.750055"},
        {name: "Псков", key: "psk", coords: "57.819274,28.33246"},
        {name: "Ростов-на-Дону", key: "rnd", coords: "47.222078,39.720358"},
        {name: "Рязань", key: "ryazan", coords: "54.629565,39.741917"},
        {name: "Самара", key: "smr", coords: "53.195878,50.100202"},
        {name: "Саранск", key: "sar", coords: "54.187433,45.183938"},
        {name: "Саратов", key: "saratov", coords: "51.533562,46.034266"},
        {name: "Сергиев Посад", key: "msk", coords: "56.315311,38.135972"},
        {name: "Серпухов", key: "msk", coords: "54.913681,37.416601"},
        {name: "Смоленск", key: "sml", coords: "54.782635,32.045287"},
        {name: "Ставрополь", key: "stv", coords: "45.043317,41.96911"},
        {name: "Стерлитамак", key: "strl", coords: "53.630403,55.930825"},
        {name: "Сургут", key: "srg", coords: "61.241778,73.393032"},
        {name: "Сыктывкар", key: "svk", coords: "61.668797,50.836497"},
        {name: "Таганрог", key: "tgn", coords: "47.208735,38.936694"},
        {name: "Тверь", key: "tvr", coords: "56.859625,35.91186"},
        {name: "Тобольск", key: "tbl", coords: "58.200891,68.253995"},
        {name: "Тольятти", key: "tlt", coords: "53.507852,49.420411"},
        {name: "Томск", key: "tmsk", coords: "56.484645,84.947649"},
        // { name: "Тула", key: "tul", coords: "54.193122,37.617348"},
        {name: "Тюмень", key: "tmn", coords: "57.152985,65.541227"},
        {name: "Ульяновск", key: "uly", coords: "54.314192,48.403132"},
        {name: "Уфа", key: "ufa", coords: "54.735152,55.958736"},
        {name: "Ханты-Мансийск", key: "htm", coords: "61.003184,69.018911"},
        {name: "Чебоксары", key: "chb", coords: "56.139918,47.247728"},
        {name: "Челябинск", key: "chlb", coords: "55.159902,61.402554"},
        {name: "Череповец", key: "chrep", coords: "59.122612,37.90347"},
        {name: "Черкесск", key: "cherk", coords: "44.228374,42.048279"},
        {name: "Шахты", key: "shakhty", coords: "47.709237,40.215401"},
        {name: "Энгельс", key: "eng", coords: "51.50105,46.124052"},
        {name: "Юрга", key: "yurga", coords: "55.713557,84.933878"},
        {name: "Ярославль", key: "yar", coords: "57.626559,39.893813"},
    ];
}