import {
    Avatar,
    Tabbar,
    TabbarItem,
    IconButton,
} from "@vkontakte/vkui";

import {
    Icon28ShoppingCartOutline,
    Icon28FavoriteOutline,
    Icon28HomeOutline,
    Icon28ScanViewfinderOutline,
    Icon28Profile,
    Icon24ErrorCircleOutline,
    Icon24PlaceOutline
} from '@vkontakte/icons';
import couponImg from './img/coupon.svg';
import couponImgActive from './img/couponActive.svg';

const TabbarLenta = ({ active, go }) => {
    return (
        <Tabbar style={{ padding: "5px 0 10px" }}>
           
            <TabbarItem
                text="Найти «Ленту»"
                selected={active === "map"}
                onClick={go}
                data-to='map'
            >
                <Icon24PlaceOutline />
            </TabbarItem>
            <TabbarItem
                text="Каталоги"
                selected={active === "main" || active === "catalog"}
                onClick={go}
                data-to='main'
            >
                <Icon28ShoppingCartOutline
                />
            </TabbarItem>
            <TabbarItem
                text="Акции"
                selected={active === "promo" || active === "event"}
                onClick={go}
                data-to='promo'
            >
                <Icon28FavoriteOutline
                />
            </TabbarItem>
            <TabbarItem
                text="Купоны"
                selected={active === "coupon"}
                onClick={go}
                data-to='coupon'
            >
                <IconButton style={{height: 28}}>
                    {active === "coupon" ?
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_1701_19951"  x="0" y="0" width="28" height="28">
                                <rect width="28" height="28" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1701_19951)">
                                <rect x="3" y="7" width="2" height="14" fill="#2975CC"/>
                                <rect x="16" y="7" width="2" height="14" fill="#2975CC"/>
                                <rect x="7" y="7" width="2" height="14" fill="#2975CC"/>
                                <rect x="20" y="7" width="2" height="14" fill="#2975CC"/>
                                <rect x="23" y="7" width="2" height="14" fill="#2975CC"/>
                                <rect x="11" y="7" width="4" height="14" fill="#2975CC"/>
                            </g>
                        </svg>
    
                        :
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_1701_19951"  x="0" y="0" width="28" height="28">
                                <rect width="28" height="28" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1701_19951)">
                                <rect x="3" y="7" width="2" height="14" fill="#99A2AD"/>
                                <rect x="16" y="7" width="2" height="14" fill="#99A2AD"/>
                                <rect x="7" y="7" width="2" height="14" fill="#99A2AD"/>
                                <rect x="20" y="7" width="2" height="14" fill="#99A2AD"/>
                                <rect x="23" y="7" width="2" height="14" fill="#99A2AD"/>
                                <rect x="11" y="7" width="4" height="14" fill="#99A2AD"/>
                            </g>
                        </svg>
                    }
                
                </IconButton>
               
                
                
            </TabbarItem>
            <TabbarItem
                text="Помощь"
                selected={active === "help"}
                onClick={go}
                data-to='help'

            >
                <Icon24ErrorCircleOutline />
            </TabbarItem>
        </Tabbar>
    )
}

export default TabbarLenta 