import { Panel, PanelHeader, PanelHeaderBack, Group, IconButton, SimpleCell, Header } from '@vkontakte/vkui';
import TabbarLenta from '../../components/TabbarLenta/TabbarLenta';
import MainBlock from '../../components/MainBlock/MainBlock'
import PanelBannerTop from '../../components/PanelBannerTop/PanelBannerTop'
const Actions = ({ go, active }) => {
    return (

        <Panel >
            <PanelHeader
                transparent={true}
                separator={false}
                visor={false}
                left={
                    <PanelHeaderBack
                        onClick={go}
                        data-to='main'
                        className='white-back'
                    />
                }>Акции</PanelHeader>
            <TabbarLenta
                active={active}
                go={go}
            ></TabbarLenta>
            <PanelBannerTop
                bg={'BindBg'}
            ></PanelBannerTop>
            <Group>
                <MainBlock
                    title='Скидка 20% на всю зиму'
                    text='по промокоду LENTAFREE'
                    buttonText='Подробнее'
                    buttonLink='actions'
                    go={go}
                    img={'#'}
                />
                <MainBlock
                    title='Henkel дарит подарки'
                    text='Получайте дополнительные баллы на Карту №1'
                    buttonText='Смотреть каталог'
                    buttonLink='actions'
                    go={go}
                    img={'#'}
                />

            </Group>
        </Panel>

    )
}

export default Actions