import './Promo.css'

import {
    Div,
    Button,
    Panel,
    PanelHeader,
    CardGrid,
    Link,
    Tabs,
    TabsItem,
    Spacing,
    Text,
    Title, ScreenSpinner, IconButton
} from "@vkontakte/vkui";

const axios = require('axios').default;



import TabbarLenta from '../../components/TabbarLenta/TabbarLenta'
import {useEffect, useState} from 'react';
import React from "react";

import createCard from "../../functions/createCard";
import SwitchMarket from "../../components/SwitchMarket/SwitchMarket";
import {Icon28ChevronBack} from "@vkontakte/icons";
import BlockEmpty from "../../components/BlockEmpty/BlockEmpty";

const Promo = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.marketType === 'minimarket') {
            props.changeMarketType('hypermarket')
        }
        
        let url = window.location.origin;
        if (url.indexOf('localhost') >= 0 || url.indexOf('plus-tech') >= 0 || url.indexOf('vk-apps') >= 0) {
            url = 'https://devvkminiapp.lenta.com'
        }
        
        if (!data) {
            axios.get(url + `/api/promo?city_key=${props.selectedCityKey}&market_type=${props.marketType}`)
                .then(function (response) {
                    // обработка успешного запроса
                    if (response.data && response.data.length) {
                        setData(response.data)
                    }
    
                    else {
                        setData({
                            error: 'Empty'
                        })
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    // обработка ошибки
                    console.log(error);
                    // setData([{"id":15,"name":"Получи набор классных тату и крутых патчей в подарок!","date":"С 29 сентября по 10 ноября","img":"https:\/\/devvkminiapp.lenta.com\/storage\/categories-app\/November2022\/7p5e4kDTrgkCAWwhbDbT.jpg","button":"Узнать больше", "city":null,"created_at":"2022-11-03T17:27:14.000000Z","updated_at":"2022-11-03T17:27:14.000000Z","url":"https:\/\/lenta.com\/globalassets\/---Yandex-Google\/-2\/black_november\/advertisementfeed_%CITY%.yml"}])
                })
        }
        
        
        
    }, [])
    
    
    let content = <ScreenSpinner state="loading" id={'main-spinner'}/>;
    
    if (data && data.error) {
        content = <BlockEmpty
            changeActualCatalogCard={props.changeActualCatalogCard}
            changeSelectedCatalogID={props.changeSelectedCatalogID}
            go={props.go}
            type={'promo'}
        />
    } else if (data && data.length) {
        let selectedCards = data.filter(item => {
            return item.market_type === props.marketType || item.market_type === 'both'
        })
        
        let cardsElems = [];
        
        if (Array.isArray(selectedCards) && selectedCards.length) {
            cardsElems = selectedCards.map(item => {
                return createCard({...item, category_img: item.img, buttonLink: 'event'}, {
                        isCatalog: false,
                        eventData: item,
                        setEventData: props.setEventData
                    },
                    props.go, props.changeSelectedCatalogID, props.changeActualCatalogCard, props.openModal)
            })
        } else {
            cardsElems = <BlockEmpty
                changeActualCatalogCard={props.changeActualCatalogCard}
                changeSelectedCatalogID={props.changeSelectedCatalogID}
                go={props.go}
                type={'promo'}
            />
        }
    
       
        
        
        
        
        content = <React.Fragment>
            <Div size="l">
                
                {cardsElems}
            
            
            </Div>
           
            
            
            
            
        
        </React.Fragment>
    }
    
    return (
        <Panel id="main">
            <PanelHeader
                left={
                    <IconButton>
                        <Icon28ChevronBack
                            onClick={props.go}
                            data-to='main'
                        />
                    </IconButton>
                }
            >Лента</PanelHeader>
            <div className={'Promo'} style={{paddingBottom: 120, paddingTop: 10}}>
                <Div className={'Promo_top'}>
                    {/*<Title level="3" style={{marginBottom: 15}}>*/}
                    {/*    Добро пожаловать в «Ленту»*/}
                    {/*</Title>*/}
                    
                    
                    <Title level="2" style={{marginBottom: 10}}>
                        Свежие акции «Ленты»
                    </Title>
                    <Text style={{fontSize: '15px'}}>
                        Суперскидки, товары за 1 рубль, розыгрыши призов и другие выгодные акции.
                    </Text>
                </Div>
                <SwitchMarket marketType={props.marketType} changeMarketType={props.changeMarketType} disabledMini={true}/>
                {/*<Div>*/}
                {/*    {props.selectedMarket ?*/}
                {/*        <Text size={13} style={{color: '#818C99'}}>*/}
                {/*            {props.selectedMarket}*/}
                {/*        </Text>*/}
                {/*        :*/}
                {/*        null*/}
                {/*    }*/}
                {/*   */}
                {/*    /!*<Text style={{fontSize: '15px'}}>*!/*/}
                {/*    /!*    Специально для вас собрали самые выгодные&nbsp;предложения по Карте №1 в&nbsp;магазинах «Гипер&nbsp;Лента». Не пропустите!*!/*/}
                {/*    /!*</Text>*!/*/}
                {/*    */}
                {/*    /!*<Spacing size={14}/>*!/*/}
                {/*    /!*<Text size={13}>*!/*/}
                {/*    /!*    <Link onClick={props.go}*!/*/}
                {/*    /!*          data-to='map'>Выбрать другой город или магазин</Link>*!/*/}
                {/*    /!*</Text>*!/*/}
                {/*    /!*<Spacing size={6}/>*!/*/}
                {/*</Div>*/}
                {content}
                <Div className={'Main_bottom'}>
                    <Text style={{fontSize: '15px'}}>
                        Ваш город {props.selectedCityName}
                    </Text>
                    <Text style={{fontSize: '13px'}}>
                        <Link onClick={props.go}
                              data-to='cities'>Выбрать другой город
                        </Link>
                    </Text>
                </Div>
            </div>
            <div style={{zIndex: 7}}>
                <TabbarLenta
                    active={props.active}
                    go={props.go}
                />
            </div>
        </Panel>
    )
    
}

export default Promo