import {
    Button,
    Div,
    SimpleCell,
} from '@vkontakte/vkui';
import {Icon16ErrorCircleFill, Icon28CheckCircleFill, Icon12Clock, Icon16InfoOutline} from '@vkontakte/icons';
import './BlockEmpty.css'
import cat from './img/cat.png';

const BlockEmpty = (props) => {
    
    return (
        <Div className={'BlockEmpty'}>
            <div className={`BlockEmpty_text BlockEmpty_text_${props.type}`}>
    
            </div>
            <img className={'BlockEmpty_img'} src={cat} width={'292px'}/>
            {/*<Button*/}
            {/*    stretched={true}*/}
            {/*    size='l'*/}
            {/*    onClick={(e) => {*/}
            {/*        props.changeActualCatalogCard(false)*/}
            {/*        props.changeSelectedCatalogID('all')*/}
            {/*        props.go(e)*/}
            
            {/*    }}*/}
            {/*    data-to={'catalog'}*/}
            {/*    appearance={'accent'}*/}
            {/*>Показать все товары</Button>*/}
            
        </Div>
    )
}

export default BlockEmpty